import React, { useState, useEffect } from "react";
import {useParams, useHistory} from "react-router-dom";
import {HiPencil, HiOutlineDocumentText} from "react-icons/hi";
import moment from "moment"

import usePageVisibility from "../../hooks/usePageVisibility"
import WishlistProduct from "../../components/wishlist-product";
import InfoDialog from "../../components/info-dialog";

import ScapeXMobile from "../../scapeX-mobile/scapeX-mobile.js";

const VisitaPage = ({obtenerVisita, eliminarProducto, eliminarSerie, finalizarVisita, guardarNotasVisita, editarClienteVisita}) => {
  const [visitaData, setVisitaData] = useState(null)
  const [notas, setNotas] = useState("")
  const [isNotasChanged, setNotasChanged] = useState(false)
  const [isEditVisitaChanged, setEditVisitaChanged] = useState(false)

  const [isEditVisita, setEditVisita] = useState(false)
  const [isInfoOpen, setInfoOpen] = useState(false)
  const [isNotasOpen, setNotasOpen] = useState(false)

  const [isFinishLoading, setIsFinishLoading] = useState(false)
  const [isNotasLoading, setNotasLoading] = useState(false)
  const [isEditVisitaLoading, setEditVisitaLoading] = useState(false)

  const [editFormData, setEditFormData] = useState({
    cliente: "",
    correo: "",
    esCliente: null,
    fecha: moment().format("YYYY-MM-DD")
  })

  const [editFormErrors, setEditFormErrors] = useState({
    cliente: null,
    correo: null,
    esCliente: null,
    fecha:null,
    general: null
  })

  const { visitaId } = useParams()
  const pageVisibility = usePageVisibility();
  const history = useHistory();

  useEffect(() => {
    setNotasLoading(false)
    setEditVisitaLoading(false)
    if (visitaData) {
      localStorage.setItem("tau-pamesa-current-visita", visitaData.id)
    }
  }, [visitaData])

  useEffect(() => {
    obtenerVisita(visitaId)
      .then((visita) => {
        visita.items = parseVisitaItems(visita)
        setVisitaData(visita)
        setEditFormData({
          cliente: visita.cliente,
          correo: visita.correo,
          esCliente: visita.esCliente,
          fecha: moment(visita.fecha_creacion).format("YYYY-MM-DD")
        })
        setNotas(visita.notas || "")
      })
      .catch((e) => {
        console.error(e)
      })
  }, [visitaId, pageVisibility, obtenerVisita])

  const parseVisitaItems = (visita) => {
    return [...visita.productos, ...visita.series]
      .sort((a,b) => a.createdAt < b.cretedAt ? 1 : -1)
      .map(item => ({
        ...item,
        discriminator: item.nombre ? "serie" : "producto",
        extra: item.nombre ? item.visita_series : item.visita_productos
      }))
  }

  const eliminaProducto = (productId) => {
    eliminarProducto(visitaId, productId)
      .then(() => {
        obtenerVisita(visitaId)
          .then((visita) => {
            visita.items = parseVisitaItems(visita)
            setVisitaData(visita)
          })
          .catch((e) => {
            console.error(e)
          })
      }).catch((e) => {
        console.error(e)
      })
  }

  const eliminaSerie = (serie) => {
    eliminarSerie(visitaId, serie)
      .then(() => {
        obtenerVisita(visitaId)
          .then((visita) => {
            visita.items = parseVisitaItems(visita)
            setVisitaData(visita)
          })
          .catch((e) => {
            console.error(e)
          })
      }).catch((e) => {
        console.error(e)
      })
  }

  const handleAddSerie = () => {
    history.push("/seleccion-serie")
  }

  const handleFinishVisit = () => {
    if (isFinishLoading) return;
    setIsFinishLoading(true)
    finalizarVisita(visitaId)
      .then(() => {
        setInfoOpen(true)
        obtenerVisita(visitaId)
          .then((visita) => {
            visita.items = parseVisitaItems(visita)
            setVisitaData(visita)
            setNotas(visita.notas || "")
          })
          .catch((e) => {
            console.error(e)
          })
      }).catch((e) => {
        console.error(e)
      })
      .finally(() => {
        setIsFinishLoading(false)
      })
  }

  const handleNotasSave = () => {
    if (!isNotasChanged) {
      setNotasOpen(false)
    } else {
      setNotasLoading(true)
      guardarNotasVisita(visitaId, notas)
        .then(() => {
          obtenerVisita(visitaId)
            .then((visita) => {
              setNotas(visita.notas || "")
              setTimeout(() => {
                setNotasLoading(false)
                setNotasChanged(false)
              }, 1000)
            })
        })
        .catch(() => {
          setTimeout(() => {
            setNotasLoading(false)
            setNotasChanged(false)
          }, 1000)
        })
    }
  }

  const handleEditVisita = () => {
    if (!isEditVisitaChanged) {
      setEditVisita(false)
    } else {
      let isErrored = false
      let _formErrors = {
        cliente: null,
        correo: null,
        esCliente: null,
        fecha_creacion:null,
        general: null
      }

      if (!editFormData.cliente || editFormData.cliente.length < 1) {
        _formErrors.cliente = {
          message: "Debe informar el campo"
        }
        isErrored = true
      }

      if (!editFormData.correo || editFormData.correo.length < 1) {
        _formErrors.correo = {
          message: "Debe informar el campo"
        }
        isErrored = true
        //eslint-disable-next-line
      } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(editFormData.correo))) {
        _formErrors.correo = {
          message: "La dirección no es correcta"
        }
        isErrored = true
      }

      if (editFormData.esCliente === null) {
        _formErrors.esCliente = {
          message: "Debe informar el campo"
        }
        isErrored = true
      }

      setEditFormErrors(_formErrors)
      if (!isErrored) {
        setEditVisitaLoading(true)
        editarClienteVisita(visitaId, editFormData)
          .then(() => {
            obtenerVisita(visitaId)
              .then((visita) => {
                visita.items = parseVisitaItems(visita)
                setVisitaData(visita)
                setEditFormData({
                  cliente: visita.cliente,
                  correo: visita.correo,
                  esCliente: visita.esCliente,
                  fecha: moment(visita.fecha_creacion).format("YYYY-MM-DD")
                })
                setNotas(visita.notas || "")
                setEditVisitaLoading(false)
                setEditVisitaChanged(false)
                setEditVisita(false)
              })
              .catch((e) => {
                setEditFormErrors({...editFormErrors, general: "Se ha producido un error"})
              })
          })
          .catch(() => {
            setTimeout(() => {
              setEditVisitaLoading(false)
              setEditFormErrors({...editFormErrors, general: "Se ha producido un error"})
            }, 1000)
          })
      }
    }
  }

  return visitaData && (
    <div className={"container mx-auto p-4 max-w-screen-md"}>
      <div className={"grid grid-cols-1"}>
        <div className={"flex flex-col justify-center items-center relative"}>
          <div role={"button"} tabIndex={-1}
            className={"absolute top-0 left-0 w-12 h-12 md:w-10 md:h-10 rounded-full flex justify-center items-center"}
            onClick={() => setEditVisita(!isEditVisita)}
            onKeyDown={() => setEditVisita(!isEditVisita)}
          >
            <HiPencil className={`text-2xl md:text-xl ${isEditVisita ? "text-gray-400" : "text-black"}`} />
          </div>

          <h2 className="text-2xl md:text-2xl font-medium leading-tight text-black text-center uppercase">visita de</h2>
          <h2 className={"text-2xl md:text-2xl leading-tight text-black text-center uppercase font-bold w-4/5"}>{visitaData.cliente}</h2>
          <span className={"text-sm text-center mt-2"}>{visitaData.correo}</span>

          <div role={"button"} tabIndex={-1}
            className={"absolute top-0 right-0 w-12 h-12 md:w-10 md:h-10 rounded-full flex justify-center items-center"}
            onClick={() => setNotasOpen(true)}
            onKeyDown={() => setNotasOpen(true)}
          >
            <HiOutlineDocumentText className={"text-2xl md:text-xl"} />
          </div>
        </div>
      </div>

      {!visitaData.finalizada && (
        <div className={"grid-cols-1 pt-3 pb-2"}>
          <div className={"flex flex-row justify-center my-5"}>
            <div role={"button"} tabIndex={-1}
              className={(isFinishLoading ? "bg-gray-800" : "bg-black") + " h-12 md:h-10 rounded-full flex justify-center items-center text-white px-3"}
              onClick={handleFinishVisit}
              onKeyDown={handleFinishVisit}
            >
              <span className={"text-lg"}>{isFinishLoading ? "Cargando..." : "Finalizar visita"}</span>
            </div>
          </div>
        </div>
      )}

      <ScapeXMobile visitorId={visitaId}></ScapeXMobile>

      {visitaData.finalizada && visitaData.notas && visitaData.notas.length > 0 && (
        <div className={"grid-cols-1 pt-3 pb-2"}>
          <div className={"flex flex-col items-center justify-center my-5"}>
            <span className={"text-xl text-center mt-2 uppercase font-bold"}>Notas</span>
            <p className={"text-md text-left mt-1"}>{visitaData.notas}</p>
          </div>
        </div>
      )}

      <div className={"grid grid-cols-1 mb-3"}>
        {visitaData.items && visitaData.items.length === 0 && (
          <div className={"flex flex-col justify-center items-center mt-10 text-neutral-800"}>
            <span className={"my-3 mt-5 text-center text-2xl sm:text-2xl"}>La lista está vacía.</span>
            <span className={"my-3 mt-4 text-center text-xl sm:text-2xl"}>Escanea productos para añadirlos o <button className={"underline decoration-blue-800 text-blue-800"} onClick={handleAddSerie}>añádelos manualmente</button>.</span>
            <img src={"/assets/images/nfc.svg"} alt="nfc" className={"w-2/5 mt-3"} />
          </div>
        )}
        {visitaData.items && visitaData.items.length > 0 && (
          <>
            <div className={"flex flex-row justify-center items-center my-3"}>
              <span className={"my-3 mt-4 text-center text-lg sm:text-lg"}>Escanea productos para añadirlos o <button className={"underline decoration-blue-800 text-blue-800"} onClick={handleAddSerie}>añádelos manualmente</button>.</span>
            </div>
            <div className={"flex flex-col justify-center divide-y divide-neutral-300"}>
              {visitaData.items.map(producto =>
                <WishlistProduct key={producto.id} product={producto} eliminaProducto={eliminaProducto} eliminaSerie={eliminaSerie}/>
              )}
            </div>
          </>
        )}
      </div>

      {visitaData.roomsWithMaterials && visitaData.roomsWithMaterials.length > 0 && (
        <div className={"grid grid-cols-1 mt-4 mb-3"}>
          <h2 className="text-2xl md:text-2xl font-medium leading-tight text-black text-center my-3 mt-4">Tus ambientes</h2>
          <div className={"flex flex-col justify-center divide-y divide-neutral-300"}>
            {visitaData.roomsWithMaterials.map(room =>
              <div className={"grid grid-cols-1 py-5 px-2 mb-2"} key={room.id}>
                <img
                  className="object-cover rounded-lg shadow-drop-bottom h-50 md:h-50 w-full"
                  src={`https://wishlist.tauceramica.com/public/rooms/${room.image}`}
                  alt={room.name}
                />
                <span className={"mt-3 mb-1 text-lg font-medium leading-tight text-center text-gray-800 uppercase cursor-default"}>
                  {room.name}
                </span>
                {room.materials && Object.keys(room.materials).map(roomPlace => {
                  const material = room.materials[roomPlace]
                  return (
                    <div className={"flex flex-col justify-center py-2 px-2"} key={material.id}>
                      <span className={"text-sm font-bold leading-tight text-center text-gray-800 cursor-default"}>{roomPlace}</span>
                      <span className={"text-sm font-medium leading-tight text-center text-gray-600 uppercase cursor-default"}>{material.serie} - {material.color}</span>
                      <span className={"text-xs font-medium leading-tight text-center text-gray-600 uppercase cursor-default"}>{material.formato} - {material.acabado}</span>
                    </div>
                  )}
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <InfoDialog
        title={""}
        open={isInfoOpen}
        onClose={() => setInfoOpen(false)}
        disabled={isFinishLoading}
      >
        { !isFinishLoading && (<><p>{"La visita se ha finalizado correctamente."}</p><p>{"En breve se enviará el resumen de la visita."}</p></>)}
        { isFinishLoading && <p>{"Cargando..."}</p> }
      </InfoDialog>

      <InfoDialog
        title={"Notas"}
        open={isNotasOpen}
        onClose={handleNotasSave}
        disabled={isNotasLoading}
        okCaption={isNotasChanged ? "Guardar" : "Cerrar"}
      >
        { !isNotasLoading && (
          <>
            <div className="relative mb-3">
              <textarea id="notas" key={"notass"}
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                rows={10}
                placeholder=""
                value={notas}
                onChange={e => {setNotas(e.target.value); setNotasChanged(true)}}
              />
              <label htmlFor="notas"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-[10%] peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Notas</label>
            </div>
          </>
        )}
        { isNotasLoading && <p className={"text-center"}>{"Las notas de la visita han sido actualizadas"}</p> }
      </InfoDialog>

      <InfoDialog
        title={"Editar"}
        open={isEditVisita}
        onClose={handleEditVisita}
        disabled={isEditVisitaLoading}
        okCaption={isEditVisitaChanged ? "Guardar" : "Cerrar"}
      >
        { !isEditVisitaLoading && (
          <>
            <div className="relative mb-3">
              <input id="cliente" type="text"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={editFormData["cliente"]}
                onChange={e => {setEditFormData({...editFormData, cliente: e.target.value}); setEditVisitaChanged(true)}}/>
              <label htmlFor="cliente"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Nombre del cliente</label>
            </div>
            {editFormErrors.cliente && (
              <div className="pl-5 mb-3 text-normal text-red-700">
                {editFormErrors.cliente.message}
              </div>
            )}

            <div className="relative mb-3">
              <input id="correo-electronico" type="email"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={editFormData["correo"]}
                onChange={e => {setEditFormData({...editFormData, "correo": e.target.value}); setEditVisitaChanged(true)}}/>
              <label htmlFor="correo-electronico"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Correo electrónico del cliente</label>
            </div>
            {editFormErrors.correo && (
              <div className="pl-5 mb-3 text-normal text-red-700">
                {editFormErrors.correo.message}
              </div>
            )}

            <div className="relative mb-3">
              <input id="fecha" type="date"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                value={editFormData["fecha"]}
                onChange={e => {setEditFormData({...editFormData, "fecha": e.target.value}); setEditVisitaChanged(true)}}/>
              <label htmlFor="fecha"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Fecha</label>
            </div>
            {editFormErrors.fecha && (
              <div className="pl-5 mb-3 text-normal text-red-700">
                {editFormErrors.fecha.message}
              </div>
            )}

            <div className="relative mb-3">
              <select id="es-cliente"
                className={`tau-es-cliente block px-2.5 pb-2.5 pt-4 w-full text-sm bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black ${setEditFormData.esCliente !==  null ? "text-black" : "text-gray-500"}`}
                onChange={e => {setEditFormData({...editFormData, "esCliente": e.target.value === "si" ? true : e.target.value === "no" ? false : null}); setEditVisitaChanged(true)}}
                value={editFormData.esCliente ? "si" : "no"}
              >
                <option value={null}>¿Es cliente?</option>
                <option value={"si"}>Sí</option>
                <option value={"no"}>No</option>
              </select>
            </div>
            {editFormErrors.esCliente && (
              <div className="pl-5 mb-3 text-normal text-red-700">
                {editFormErrors.esCliente.message}
              </div>
            )}
          </>
        )}
        { isEditVisitaLoading && !editFormErrors.general && <p className={"text-center"}>{"Actualiznado datos..."}</p> }
        { editFormErrors.general && <p className="pl-5 mb-3 text-normal text-red-700">{editFormErrors.general}</p>}
      </InfoDialog>

    </div>
  )
}

export default VisitaPage;