import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";

import WishlistProduct from "../../components/wishlist-product";

const StaticVisitaPage = ({obtenerStaticVisita}) => {
  const [visitaData, setVisitaData] = useState(null)

  const { visitaId } = useParams()

  useEffect(() => {
    obtenerStaticVisita(visitaId)
      .then((visita) => {
        visita.items = parseVisitaItems(visita)
        setVisitaData(visita)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [visitaId, obtenerStaticVisita])

  const parseVisitaItems = (visita) => {
    return [...visita.productos, ...visita.series]
      .sort((a,b) => a.createdAt < b.cretedAt ? 1 : -1)
      .map(item => ({
        ...item,
        discriminator: item.nombre ? "serie" : "producto",
        extra: item.nombre ? item.visita_series : item.visita_productos
      }))
  }

  return visitaData && (
    <div className={"container my-5 mx-auto pt-5 p-4 max-w-screen-md"}>
      <div className={"grid grid-cols-1"}>
        <div className={"flex flex-col justify-center items-center relative"}>
          <h2 className="text-3xl md:text-2xl font-medium leading-tight text-black text-center uppercase">visita de</h2>
          <h2 className={"text-3xl md:text-2xl leading-tight text-black text-center uppercase font-bold w-4/5"}>{visitaData.cliente}</h2>
          <span className={"text-sm text-center mt-2"}>{visitaData.correo}</span>
        </div>
      </div>

      <div className={"grid grid-cols-1 mt-4 mb-3"}>
        {visitaData.items && visitaData.items.length === 0 && (
          <div className={"flex flex-col justify-center items-center mt-10 text-neutral-800"}>
            <span className={"my-3 mt-5 text-center text-2xl sm:text-2xl"}>La lista está vacía.</span>
          </div>
        )}
        {visitaData.items && visitaData.items.length > 0 && (
          <>
            <div className={"flex flex-col justify-center divide-y divide-neutral-300"}>
              {visitaData.items.map(producto =>
                <WishlistProduct key={producto.id} product={producto} staticVisit={true}/>
              )}
            </div>
          </>
        )}
      </div>

      {visitaData.rooms && visitaData.rooms.length > 0 && (
        <div className={"grid grid-cols-1 mt-4 mb-3"}>
          <h2 className="text-2xl md:text-2xl font-medium leading-tight text-black text-center my-3 mt-4">Tus ambientes</h2>
          <div className={"flex flex-col justify-center divide-y divide-neutral-300"}>
            {visitaData.rooms.map(room =>
              <div className={"grid grid-cols-1 py-5 px-2"} key={room.id}>
                <img
                  className="object-cover rounded-lg shadow-drop-bottom h-50 md:h-50 w-full"
                  src={`https://wishlist.tauceramica.com/public/rooms/${room.image}`}
                  alt={room.name}
                />
                <span className={"mt-3 mb-3 text-lg font-medium leading-tight text-center text-gray-800 uppercase cursor-default"}>
                  {room.name}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default StaticVisitaPage;