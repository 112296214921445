import { MqttClient } from "./capore/mqtt_client.js";

class ClientAppMqttClient extends MqttClient {
  visitorId
  scapeXMobileComponent
  constructor(scapeXMobileComponent, roomId, deviceId) {
    var topicPrefix = "tau" + "/device/" + deviceId + "/" + roomId + "/";
    super("wss://tau.easire.com/mqtt", deviceId, topicPrefix);
    this.scapeXMobileComponent = scapeXMobileComponent;
    this.visitorId = scapeXMobileComponent.props.visitorId;
    this.subscribe(topicPrefix+ "request/visitorId",  this.onRequest);
  }

  onRequest = () => {
    this.send("send/visitorId", this.visitorId, 1, false)
    this.scapeXMobileComponent.buttonText = "Connected"
    this.scapeXMobileComponent.setState({ connectedToTable: true })
  }
}

export { ClientAppMqttClient }