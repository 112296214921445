import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Header from "../../components/header";
import { getImageUrl } from "../../utils";

const AdminProductosPage = ({obtenerAdminProductos}) => {

  const history = useHistory()

  const [loading, setLoading] = useState(false);
  const [productosData, setProductosData] = useState([])
  const [productosColumns, setProductosColumns] = useState([])
  const [productosDataFiltered, setProductosDataFiltered] = useState([])
  const [filterText, setFilterText] = useState("");


  useEffect(() => {
    const columns = [
      {
        name: "Serie",
        selector: row => row.serie,
        sortable: true
      },
      {
        name: "Color",
        selector: row => row.color,
        sortable: true
      },
      {
        name: "Formato",
        selector: row => row.formato,
        sortable: true
      },
      {
        name: "Acabado",
        selector: row => row.acabado,
        sortable: true
      },
    ];
    setProductosColumns(columns)
  }, []);

  useEffect(() => {
    setLoading(true)
    obtenerAdminProductos()
      .then((productos) => {
        setProductosData(productos)
        setProductosDataFiltered(productos)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }, [obtenerAdminProductos])

  useEffect(() => {
    if (filterText === "") {
      setProductosDataFiltered(productosData);
    } else {
      setProductosDataFiltered(productosData.filter((p) => {
        return (p.serie && p.serie.toLowerCase().includes(filterText.toLowerCase())) ||
          (p.color && p.color.toLowerCase().includes(filterText.toLowerCase())) ||
          (p.acabado && p.acabado.toLowerCase().includes(filterText.toLowerCase())) ||
          (p.formato && p.formato.toLowerCase().includes(filterText.toLowerCase()));
      }))
    }
  }, [filterText])

  const ExpandedProductoComponent = ({ data }) => {
    const product = data
    return (
      <div className={"grid grid-cols-1 sm:grid-cols-8 py-5 px-2"} key={product.id}>
        <div
          className={
            "col-span-1 sm:col-span-2 px-4 flex flex-col justify-center items-center my-2 sm:my-0"
          }
        >
          <img
            className=" object-cover rounded-full shadow-drop-bottom h-14 md:h-14 w-full"
            src={getImageUrl(product)}
            alt={product.color}
          />
        </div>
        <div
          className={
            "col-span-1 sm:col-span-3 px-4 flex flex-col justify-center my-2 sm:my-0"
          }
        >
          <div className={"flex flex-col justify-center my-1"}>
            <span
              className={
                "text-sm font-medium leading-tight text-center text-gray-800 uppercase cursor-default"
              }
            >
              {product.serie} - {product.color}
            </span>
          </div>
        </div>
        <div
          className={
            "col-span-1 sm:col-span-1 px-4 flex flex-col justify-center my-2 sm:my-0"
          }
        >
          <div className={"flex flex-row justify-center my-1"}>
            <span
              className={
                "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-full text-gray-800 text-sm uppercase cursor-default"
              }
            >
              {product.formato}
            </span>
          </div>
        </div>
        <div
          className={
            "col-span-1 sm:col-span-2 px-4 flex flex-col justify-center my-2 sm:my-0"
          }
        >
          <div className={"flex flex-row justify-center my-1"}>
            <span
              className={
                "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-full text-gray-800 text-sm uppercase cursor-default"
              }
            >
              {product.acabado}
            </span>
          </div>
        </div>
      </div>
    )
  };

  return (
    <>
      <div className={"container mx-auto p-4"}>
        <Header onBack={history.goBack} />
        <div className={"flex justify-center my-5"}>
          <h2 className="text-2xl md:text-3xl font-medium font-tau-serif leading-tight text-black text-center lowercase">productos</h2>
        </div>
        <div className={"flex justify-center mt-5 mb-3 px-5"}>
          <div className="relative mb-3 w-full sm:w-2/3 lg:w-1/3">
            <input id="product-search" type="text"
              className="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={filterText}
              onChange={e => setFilterText(e.target.value)}/>
            <label htmlFor="product-search" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Buscar</label>
          </div>
        </div>
        <div className={"container mx-auto p-4 max-w-screen-md"}>
          <DataTable
            columns={productosColumns}
            data={productosDataFiltered}
            pagination
            progressPending={loading}
            expandOnRowClicked
            expandableRows
            expandableRowsHideExpander
            expandableRowsComponent={ExpandedProductoComponent}
          />
        </div>
      </div>
    </>
  )
}

export default AdminProductosPage;