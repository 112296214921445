import React from "react";
import { Route, Redirect } from "react-router-dom";

const adminSecrets = [
  "u4SfUdhWk3MqpxZcv6eHbE",
  "GKJP96RXtCvkj8xHmsNQhW",
  "uaM3XrGVpzbJW25g9yRscZ"
]

const RouteGuard = ({ children, adminRoute, ...rest }) => {
  function hasJWT() {
    const user = localStorage.getItem("tau-current-comercial")
    return !!user
  }

  function isAdmin() {
    const secretAdmin = localStorage.getItem("tau-current-admin")
    return adminSecrets.includes(secretAdmin)
  }

  return (
    <Route {...rest}
      render={() => (
        hasJWT() ? ((adminRoute && isAdmin()) || !adminRoute ? children : <Redirect to={{ pathname: "/admin/login" }} /> ) : <Redirect to={{ pathname: "/" }} />
      )}
    />
  );
};

export default RouteGuard;