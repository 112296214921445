import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment"
import "moment/locale/es"
import Header from "../../components/header";
import ConfirmDialog from "../../components/confirm-dialog";
import {HiPencil, HiTrash} from "react-icons/hi";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

// eslint-disable-next-line
const emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

const AdminComercialesPage = ({obtenerAdminComerciales, createComercial, updateComercial, eliminarAdminComercial}) => {
  moment.locale("es")

  const history = useHistory()

  const [loading, setLoading] = useState(false);
  const [comercialesData, setComercialesData] = useState([])
  const [comercialesColumns, setComercialesColumns] = useState([])

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const [comercialUsername, setComercialUsername] = useState("")
  const [comercialEmail, setComercialEmail] = useState("")
  const [comercialTipo, setComercialTipo] = useState("")

  const [comercialEliminar, setComercialEliminar] = useState(null)

  const [isCreation, setIsCreation] = useState(true)
  const [formError, setFormError] = useState(null)

  const [startDateSelected, setStartDateSelected] = useState(null)
  const [finishDateSelected, setFinishDateSelected] = useState(null)


  useEffect(() => {
    const columns = [
      {
        name: "Comercial",
        selector: row => row.username,
        sortable: true,
        sortFunction: (a, b) => a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1
      },
      {
        name: "Email",
        selector: row => row.email || "-",
        sortable: true
      },
      {
        name: "Tipo",
        selector: row => row.tipo || "-",
        sortable: true
      },
      {
        name: "Total visitas",
        selector: row => row.totalVisitas,
        sortable: true
      },
      {
        name: "Última visita",
        selector: row => row.fechaUltimaVisita ? moment(row.fechaUltimaVisita).format("L LTS") : "-",
        sortable: true,
        sortFunction: (a, b) => !a.fechaUltimaVisita ? -1 : !b.fechaUltimaVisita ? 1 :  a.fechaUltimaVisita > b.fechaUltimaVisita ? 1 : -1
      },
      {
        cell: (data) => <>
          <button className={"mr-2"} onClick={() => {
            setIsCreation(false)
            setComercialUsername(data.username)
            setComercialEmail(data.email || "")
            setComercialTipo(data.tipo || "")
            setFormError(null);
            setConfirmOpen(true)
          }}><HiPencil/></button>
          <button onClick={() => handleRemoveClick(data)}><HiTrash/></button>
        </>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
    setComercialesColumns(columns)
  }, []);

  useEffect(() => {
    setLoading(true)
    obtenerAdminComerciales()
      .then((comerciales) => {
        setComercialesData(comerciales)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }, [obtenerAdminComerciales])

  const handleCreation = () => {
    if (comercialUsername.length < 3) {
      setFormError("El nombre de usuario debe tener mínimo 3 caracteres.")
      setConfirmOpen(true);
      return;
    }
    setConfirmOpen(false);
    setLoading(true)
    createComercial({username: comercialUsername, email: comercialEmail, tipo: comercialTipo})
      .then(() => {
        toast.success("El comercial se ha creado con éxito")
        obtenerAdminComerciales()
          .then((comerciales) => {
            setComercialesData(comerciales)
            setLoading(false)
          })
          .catch((e) => {
            console.error(e)
            setLoading(false)
          })
      })
      .catch(() => {
        toast.error("Ha ocurrido un error al crear el comercial")
        setLoading(false)
      })
    setComercialUsername("")
    setComercialEmail("")
    setComercialTipo("")
  }

  const handleEdition = () => {
    if (!comercialEmail.length || !emailRegex.test(comercialEmail)) {
      setFormError("Debe introducir un correo electrónico válido.")
      setConfirmOpen(true);
      return;
    }

    setConfirmOpen(false);
    setLoading(true)
    updateComercial({username: comercialUsername, email: comercialEmail, tipo: comercialTipo})
      .then(() => {
        toast.success("El comercial ha sido actualizado con éxito")
        obtenerAdminComerciales()
          .then((comerciales) => {
            setComercialesData(comerciales)
            setLoading(false)
          })
          .catch((e) => {
            console.error(e)
            setLoading(false)
          })
      })
      .catch(() => {
        toast.error("Ha ocurrido un error al actualizar el comercial")
        setLoading(false)
      })
    setComercialUsername("")
    setComercialEmail("")
  }

  const handleRemoveClick = (data) => {
    setComercialEliminar(data);
    setConfirmDeleteOpen(true);
  }

  const handleDelete = () => {
    setConfirmDeleteOpen(false);
    setLoading(true)
    eliminarAdminComercial(comercialEliminar.username)
      .then(() => {
        toast.success("El comercial se ha eliminado con éxito")
        obtenerAdminComerciales()
          .then((comerciales) => {
            setComercialesData(comerciales)
            setLoading(false)
          })
          .catch((e) => {
            console.error(e)
            setLoading(false)
          })
      })
      .catch(() => {
        toast.error("Ha ocurrido un error al eliminar el comercial")
        setLoading(false)
      })
    setComercialEliminar(null);
  }

  const actualizarComerciales = () => {
    setLoading(true)
    obtenerAdminComerciales(startDateSelected, finishDateSelected)
      .then((comerciales) => {
        setComercialesData(comerciales)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }

  return (
    <>
      <Toaster toastOptions={{
        success: {
          iconTheme: {
            primary: "black",
            secondary: "white",
          },
        },
      }}/>
      <div className={"container mx-auto p-4"}>
        <Header onBack={history.goBack} />
        <div className={"flex justify-center my-5"}>
          <h2 className="text-2xl md:text-3xl font-medium font-tau-serif leading-tight text-black text-center lowercase">comerciales</h2>
        </div>
        <div className={"flex flex-col md:flex-row justify-center pt-5"}>
          <div className={"flex flex-col justify-center items-center md:items-start px-4"}>
            <span className="text-sm md:text-md mb-2 ml-1 md:ml-2">Filtrar desde fecha:</span>
            <DatePicker
              selected={startDateSelected}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {
                setStartDateSelected(moment(date).toDate("X"))
              }}
            />
          </div>
          <div className={"flex flex-col justify-center items-center md:items-start px-4"}>
            <span className="text-sm md:text-md mb-2 ml-1 md:ml-2">Filtrar hasta fecha:</span>
            <DatePicker
              selected={finishDateSelected}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {
                setFinishDateSelected(moment(date).toDate("X"))
              }}
            />
          </div>
        </div>
        <div className={"flex flex-col justify-center items-center mt-3 mb-5"}>
          <button type="button" className={"my-4 px-4 py-3 rounded-full bg-black text-white text-xs tracking-widest shadow-drop-bottom uppercase"}
            onClick={() => actualizarComerciales()}>
            Filtrar
          </button>
        </div>
        <div className={"center mx-auto p-4 max-w-screen-lg"}>
          <button type="button" className={"my-4 px-4 py-3 rounded-full bg-black text-white text-xs tracking-widest shadow-drop-bottom uppercase"}
            onClick={() => {setFormError(null); setComercialUsername(""); setIsCreation(true); setConfirmOpen(true);}}>
            Crear comercial
          </button>
          <DataTable
            style={{width: "100%"}}
            columns={comercialesColumns}
            data={comercialesData}
            pagination
            progressPending={loading}
          />
          <ConfirmDialog
            title="Eliminar comercial"
            open={confirmDeleteOpen}
            onClose={() => setConfirmDeleteOpen(false)}
            onConfirm={handleDelete}
          >
            {comercialEliminar &&
            <>
              <span>¿Está seguro de eliminar el comercial </span>
              <span className={"font-bold"}>{comercialEliminar.username}</span>
              <span>?</span>
            </>
            }
          </ConfirmDialog>
          <ConfirmDialog
            title={ isCreation ? "Crear comercial" : "Editar comercial" }
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
            onConfirm={isCreation ? handleCreation : handleEdition}
            si={ isCreation ? "Crear" : "Editar"}
            no={"Cancelar"}
          >
            <div className="relative mb-3">
              <input id="username" type="text" disabled={!isCreation}
                className="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={comercialUsername}
                onChange={e => setComercialUsername(e.target.value)}/>
              <label htmlFor="username"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Usuario del comercial</label>
            </div>
            <div className="relative mb-3">
              <input id="email" type="email"
                className="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={comercialEmail}
                onChange={e => setComercialEmail(e.target.value)}/>
              <label htmlFor="email"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Dirección correo electrónico</label>
            </div>
            {formError && (
              <div className="pl-5 mb-3 text-normal text-red-700">
                {formError}
              </div>
            )}
            <div className="relative mb-3">
              <select id="tipo"
                className="tau block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                value={comercialTipo}
                onChange={e => setComercialTipo(e.target.value)}>
                <option value={"comercial"}>{"Comercial"}</option>
                <option value={"agente"}>{"Agente"}</option>
              </select>
            </div>
          </ConfirmDialog>
        </div>
      </div>
    </>
  )
}

export default AdminComercialesPage;