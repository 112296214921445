import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
  HiOutlinePencil,
  HiOutlineMail,
  HiOutlineBadgeCheck,
  HiOutlineTrash,
  HiOutlineEye
} from "react-icons/hi";
import InfoDialog from "../../components/info-dialog";
import ConfirmDialog from "../../components/confirm-dialog";

const HomePage = ({obtenerVisitas, obtenerComercial, createComercialSecret, sendMailVisita, eliminaVisita}) => {
  const [isSecretLoading, setSecretLoading] = useState(false)

  const [isLoading, setLoading] = useState(false)

  const [mailPopupOpen, setMailPopupOpen] = useState(false)
  const [removeVisitPopupOpen, setRemoveVisitPopupOpen] = useState(false)
  const [removeVisitConfirmOpen, setRemoveVisitConfirmOpen] = useState(false)

  const [visitToRemove, setVisitToRemove] = useState(null)

  const [visitasData, setVisitasData] = useState([])
  const [comercialData, setComercialData] = useState(null)
  const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false)
  const [dateSelected, setDateSelected] = useState(moment().toDate())

  const history = useHistory()
  const { comercialUsername } = useParams()

  useEffect(() => {
    localStorage.setItem("tau-pamesa-current-visita", -1)
    localStorage.setItem("tau-current-comercial", comercialUsername)
  }, [])

  useEffect(() => {
    obtenerComercial(comercialUsername)
      .then((comercial) => {
        if (comercial === null) {
          localStorage.setItem("tau-current-comercial", "")
          window.location.replace("/")
        } else {
          setComercialData(comercial)
        }
      })
      .catch((e) => {
        console.error(e)
        localStorage.setItem("tau-current-comercial", "")
        window.location.replace("/")
      })
    obtenerVisitas(comercialUsername)
      .then((visitas) => {
        const visitasPorFecha = {}
        visitas.forEach((v) => {
          const fechasExistentes = Object.keys(visitasPorFecha)
          const fechaVisita = moment(v.fecha_creacion).format("DDMMYYYY")
          if (!fechasExistentes.includes(fechaVisita)) {
            visitasPorFecha[fechaVisita] = []
          }
          visitasPorFecha[fechaVisita].push(v)
          visitasPorFecha[fechaVisita] = visitasPorFecha[fechaVisita].sort((a, b) => b.fecha_creacion > a.fecha_creacion ? 1 : -1)
        })
        setVisitasData(visitasPorFecha)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [comercialUsername, obtenerVisitas, obtenerComercial])

  const handleSecretCreation = () => {
    setSecretLoading(true);
    createComercialSecret(comercialUsername)
      .then(() => {
        setAdditionalInfoOpen(true)
      })
      .catch(() => {
        setAdditionalInfoOpen(true)
      })
      .finally(() => {
        setSecretLoading(false)
      })
  }

  const handleEdit = (visitaId) => {
    history.push(`/${comercialUsername}/visita/${visitaId}`)
  }

  const handleMailing = (visitaId) => {
    setLoading(true)
    setMailPopupOpen(true)
    sendMailVisita(visitaId)
      .then(() => {
        obtenerVisitas(comercialUsername)
          .then((visitas) => {
            const visitasPorFecha = {}
            visitas.forEach((v) => {
              const fechasExistentes = Object.keys(visitasPorFecha)
              const fechaVisita = moment(v.fecha_creacion).format("DDMMYYYY")
              if (!fechasExistentes.includes(fechaVisita)) {
                visitasPorFecha[fechaVisita] = []
              }
              visitasPorFecha[fechaVisita].push(v)
              visitasPorFecha[fechaVisita] = visitasPorFecha[fechaVisita].sort((a, b) => b.fecha_creacion > a.fecha_creacion ? 1 : -1)
            })
            setVisitasData(visitasPorFecha)
          })
          .catch((e) => {
            console.error(e)
          })
      })
      .catch(() => {
        obtenerVisitas(comercialUsername)
          .then((visitas) => {
            const visitasPorFecha = {}
            visitas.forEach((v) => {
              const fechasExistentes = Object.keys(visitasPorFecha)
              const fechaVisita = moment(v.fecha_creacion).format("DDMMYYYY")
              if (!fechasExistentes.includes(fechaVisita)) {
                visitasPorFecha[fechaVisita] = []
              }
              visitasPorFecha[fechaVisita].push(v)
              visitasPorFecha[fechaVisita] = visitasPorFecha[fechaVisita].sort((a, b) => b.fecha_creacion > a.fecha_creacion ? 1 : -1)
            })
            setVisitasData(visitasPorFecha)
          })
          .catch((e) => {
            console.error(e)
          })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleRemove = () => {
    eliminaVisita(visitToRemove.id)
      .then(() => {
        setRemoveVisitPopupOpen(true)
        setRemoveVisitConfirmOpen(false)
        setVisitToRemove(null)
        obtenerVisitas(comercialUsername)
          .then((visitas) => {
            const visitasPorFecha = {}
            visitas.forEach((v) => {
              const fechasExistentes = Object.keys(visitasPorFecha)
              const fechaVisita = moment(v.fecha_creacion).format("DDMMYYYY")
              if (!fechasExistentes.includes(fechaVisita)) {
                visitasPorFecha[fechaVisita] = []
              }
              visitasPorFecha[fechaVisita].push(v)
              visitasPorFecha[fechaVisita] = visitasPorFecha[fechaVisita].sort((a, b) => b.fecha_creacion > a.fecha_creacion ? 1 : -1)
            })
            setVisitasData(visitasPorFecha)
          })
          .catch((e) => {
            console.error(e)
          })
      })
      .catch(() => {
        setRemoveVisitPopupOpen(true)
        setRemoveVisitConfirmOpen(false)
        setVisitToRemove(null)
        obtenerVisitas(comercialUsername)
          .then((visitas) => {
            const visitasPorFecha = {}
            visitas.forEach((v) => {
              const fechasExistentes = Object.keys(visitasPorFecha)
              const fechaVisita = moment(v.fecha_creacion).format("DDMMYYYY")
              if (!fechasExistentes.includes(fechaVisita)) {
                visitasPorFecha[fechaVisita] = []
              }
              visitasPorFecha[fechaVisita].push(v)
              visitasPorFecha[fechaVisita] = visitasPorFecha[fechaVisita].sort((a, b) => b.fecha_creacion > a.fecha_creacion ? 1 : -1)
            })
            setVisitasData(visitasPorFecha)
          })
          .catch((e) => {
            console.error(e)
          })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const renderMonthContent = (month) => {
    return <span>{moment(month+1, "M").format("MMM")}</span>;
  };

  return visitasData && comercialData && (
    <div className={"container mx-auto p-4 max-w-screen-md"}>
      {/*<div className={"grid grid-cols-1"}>*/}
      {/*  <div className={"flex justify-center pt-2 pb-4"}>*/}
      {/*    <h2 className="text-2xl md:text-3xl font-medium font-tau-serif leading-tight text-black text-center lowercase">¡Hola <span className={"font-bold"}>{comercialData.username}</span>!</h2>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={"flex flex-row justify-between md:pt-5"}>
        <div className={"w-2/5 md:w-[unset] relative grid grid-cols-1 pt-5 pb-3 md:py-0 order-last"}>
          <div className={"flex flex-col justify-end items-end"}>
            <Link to={`/${comercialUsername}/crear-visita`} className={"px-4 py-3 rounded-full bg-black text-white text-sm tracking-widest shadow-drop-bottom uppercase text-center"}>
              Crear visita
            </Link>
          </div>
        </div>

        <div className={"w-2/5 md:w-[unset] relative grid grid-cols-1 pt-5 pb-4 md:py-0"}>
          <div className={"flex flex-col justify-center items-center md:items-start"}>
            <span className="text-sm md:text-md mb-2 ml-1 md:ml-2">Ver visitas del mes:</span>
            <DatePicker
              selected={dateSelected}
              showMonthYearPicker
              dateFormat="MM/yyyy"
              renderMonthContent={renderMonthContent}
              onChange={(date) => {
                setDateSelected(moment(date).toDate())
              }}
            />
          </div>
        </div>
      </div>


      <div className={"relative grid grid-cols-1 my-3"}>
        <div className={"flex flex-col"}>
          {(visitasData && Object.keys(visitasData).sort((a, b) => moment(a, "DDMMYYYY").isBefore(moment(b, "DDMMYYYY")) ? 1 : -1)
            .filter(fecha => moment(fecha, "DDMMYYYY").format("MMYYYY") == moment(dateSelected).format("MMYYYY")).length === 0) ? (
              <div className={"grid grid-cols-1 min-h-[40vh]"}>
                <div className={"flex justify-center items-center pt-2 pb-4 h-full"}>
                  <h2 className="text-2xl md:text-3xl font-medium leading-tight text-black text-center">No hay visitas para la semana seleccionada</h2>
                </div>
              </div>
            ) :
            (
              <div className={"flex flex-col justify-start divide-y divide-neutral-300 w-full min-h-[40vh]"}>
                {
                  Object.keys(visitasData).sort((a, b) => moment(a, "DDMMYYYY").isBefore(moment(b, "DDMMYYYY")) ? 1 : -1)
                    .filter(fecha => moment(fecha, "DDMMYYYY").format("MMYYYY") == moment(dateSelected).format("MMYYYY"))
                    .map(fecha => (
                      <>
                        <div className={"grid grid-cols-1 py-0.5 pt-5 border-t-0"}>
                          <span className={"text-neutral-900"}>{moment(fecha, "DDMMYYYY").format("L")}</span>
                        </div>
                        {visitasData[fecha].map(visita => (
                          <div key={visita.id} className={"grid grid-cols-11 py-0.5"}>
                            {visita.enviada &&
                              <div className={"col-span-1 flex justify-start items-center"}>
                                <HiOutlineBadgeCheck className={"text-xl"}/>
                              </div>
                            }
                            <div className={"flex justify-start items-center " + (visita.enviada ? "col-span-4" : "col-span-5" )}>
                              <span className={"font-bold text-lg"}>{visita.cliente}</span>
                            </div>
                            <div className={"col-span-2 flex flex-row justify-end items-center px-3"}>
                              <div role={"button"} tabIndex={-1}
                                onClick={() => !isLoading && handleEdit(visita.id)}
                                onKeyDown={() => !isLoading && handleEdit(visita.id)}
                              >
                                {!visita.finalizada && <HiOutlinePencil className={"text-xl " + (isLoading ? "text-gray-500" : "")}/>}
                                {visita.finalizada && <HiOutlineEye className={"text-xl " + (isLoading ? "text-gray-500" : "")}/>}
                              </div>
                            </div>
                            <div className={"col-span-2 flex flex-row justify-end items-center px-3"}>
                              <div role={"button"} tabIndex={-1}
                                onClick={() => !isLoading && handleMailing(visita.id)}
                                onKeyDown={() => !isLoading && handleMailing(visita.id)}
                              >
                                <HiOutlineMail className={"text-xl " + (isLoading ? "text-gray-500" : "")}/>
                              </div>
                            </div>
                            <div className={"col-span-2 flex flex-row justify-end items-center px-3"}>
                              <div role={"button"} tabIndex={-1}
                                onClick={() => {setVisitToRemove(visita); setRemoveVisitConfirmOpen(true)}}
                                onKeyDown={() => {setVisitToRemove(visita); setRemoveVisitConfirmOpen(true)}}
                              >
                                <HiOutlineTrash className={"text-xl" + (isLoading ? "text-gray-500" : "")}/>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ))}
              </div>
            )}
        </div>
      </div>
      <div className={"relative grid-cols-1 pt-3 pb-1 bottom-0 grid"}>
        <div className={"flex flex-row justify-center my-5"}>
          <div role={"button"} tabIndex={-1}
            className={
              "h-12 md:h-10 rounded-full flex justify-center items-center bg-black text-white px-3"
            }
            onClick={handleSecretCreation}
            onKeyDown={handleSecretCreation}
          >
            <span className={"text-lg"}>{isSecretLoading ? "Cargando..." : "Obtener App login"}</span>
          </div>
        </div>
      </div>
      <InfoDialog
        title="App login"
        open={additionalInfoOpen}
        onClose={() => setAdditionalInfoOpen(false)}
      >
        <div className={"flex flex-col justify-center items-center my-2"}>
          <span>En breve recibirás, en tu correo electrónico, las credenciales para iniciar sesión en la App.</span>
        </div>
      </InfoDialog>
      <InfoDialog
        title={""}
        open={mailPopupOpen}
        onClose={() => setMailPopupOpen(false)}
        disabled={isLoading}
      >
        { !isLoading && <p>{"El resumen de la visita ha sido enviado correctamente."}</p> }
        { isLoading && <p>{"Enviando..."}</p> }
      </InfoDialog>
      <InfoDialog
        title={""}
        open={removeVisitPopupOpen}
        onClose={() => setRemoveVisitPopupOpen(false)}
      >
        <p>{"La visita se ha eliminado correctamente"}</p>
      </InfoDialog>
      <ConfirmDialog
        title="Eliminar visita"
        open={removeVisitConfirmOpen}
        onClose={() => {setRemoveVisitConfirmOpen(false); setVisitToRemove(null)}}
        onConfirm={handleRemove}
      >
        {!isLoading && visitToRemove && <span>¿Está seguro de eliminar la visita de {visitToRemove.cliente}?</span>}
        {isLoading && <span>Eliminando visita...</span>}
      </ConfirmDialog>
    </div>
  )
}

export default HomePage;