import React from "react";
import {HiChevronDoubleRight} from "react-icons/hi";

const ElegirMaterialesPromociones = ({nextStep, data, onDataChange}) => {
  return (
    <div>
      <h2 className="text-center text-2xl text-bold uppercase tracking-wide my-3">Materiales</h2>
      <div className={"grid grid-cols-1 gap-x-2 md:gap-x-5 xl:gap-x-8 mb-3"}>
        <form className={"w-100"}>
          <div className="relative mb-3">
            <input id="camiones" type="number"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              inputMode="numeric"
              pattern="[0-9]*"
              placeholder=" "
              value={data.camiones}
              onChange={e => onDataChange({...data, camiones: e.target.value})}/>
            <label htmlFor="camiones"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Camiones</label>
          </div>
          <div className="relative mb-3">
            <input id="palets" type="number"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              inputMode="numeric"
              pattern="[0-9]*"
              placeholder=" "
              value={data.palets}
              onChange={e => onDataChange({...data, palets: e.target.value})}/>
            <label htmlFor="palets"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Palets</label>
          </div>
          <div className="relative mb-3">
            <input id="metros2" type="number"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              inputMode="numeric"
              pattern="[0-9]*"
              placeholder=" "
              value={data.metros2}
              onChange={e => onDataChange({...data, metros2: e.target.value})}/>
            <label htmlFor="metros2"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Metros cuadrados</label>
          </div>
          <div className="relative mb-3">
            <textarea id="promociones" key={"promociones"}
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              rows={5}
              placeholder=" "
              value={data.promociones}
              onChange={e => onDataChange({...data, promociones: e.target.value})}/>
            <label htmlFor="promociones"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/4 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Promociones</label>
          </div>
        </form>
        <button
          className={"flex flex-row justify-center items-center  mt-3 px-4 py-3 rounded-full border-2 border-black bg-white text-black text-bold text-sm tracking-widest shadow-drop-bottom uppercase"}
          type="button" onClick={nextStep}>
          Siguiente <HiChevronDoubleRight className={"inline ml-2"}/>
        </button>
      </div>
    </div>
  );
};

export default ElegirMaterialesPromociones;