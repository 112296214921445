import React from "react";
import moment from "moment"
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Layout from "./components/layout";
import RouteGuard from "./components/router-gard";

import AdminLoginPage from "./pages/AdminPage/admin-login-page";
import AdminPage from "./pages/AdminPage/admin-page";
import AdminVisitasPage from "./pages/AdminPage/admin-visitas-page";
import AdminComercialesPage from "./pages/AdminPage/admin-comerciales-page";
import AdminProductosPage from "./pages/AdminPage/admin-productos-page";

import AppLoginPage from "./pages/AuthPage/app-login-page";
import HomePage from "./pages/HomePage/home-page";
import CrearVisitaPage from "./pages/CrearVisitaPage/crear-visita-page";
import VisitaPage from "./pages/VisitaPage/visita-page";
import NotFoundPage from "./pages/NotFoundPage/not-found-page";
import AnadirProductoPage from "./pages/AnadirProductoPage/anadir-producto-page";
import LandingPage from "./pages/LandingPage/landing-page";
import SeleccionSeriePage from "./pages/SeleccionSeriePage/seleccion-serie-page";

import StaticVisitaPage from "./pages/StaticVisitaPage/static-visita-page";

import "./App.css";

function App() {
  //const API_BASE_URL = "http://localhost:5000"
  const API_BASE_URL = "https://wishlist.tauceramica.com"

  const obtenerAdminProductos = () => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/admin/producto`)
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const obtenerComercial = (comercialUsername) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/comercial/${comercialUsername}`)
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const obtenerComercialPorSecreto = (comercialSecreto) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/comercial/secret/${comercialSecreto}`)
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const obtenerVisita = (visitaId) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/visita/${visitaId}`)
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const obtenerVisitas = (comercialUsername) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/comercial/${comercialUsername}/visita`)
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const obtenerStaticVisita = (visitaId) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/visita/cliente/${visitaId}`)
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const obtenerAdminVisitas = () => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/admin/visita`)
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const descargarVisitasCsv = () => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/admin/visita/csv`)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "tau-wishlist.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
          resolve()
        })
        .catch(reject)
    })
  }

  const eliminarAdminVisitas = (visitaId) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/admin/visita/${visitaId}`, {
        method: "DELETE"
      })
        .then(r => {
          if(r.ok) {
            return r.json()
          }
          reject()
        })
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const obtenerAdminComerciales = (startDate, finishDate) => {
    let queryParams = ""
    if (startDate) {
      queryParams += "startDate=" + encodeURIComponent(moment(startDate).format("X"))
    }

    if (finishDate) {
      queryParams += (startDate ? "&" : "") + "finishDate=" + encodeURIComponent(moment(finishDate).format("X"))
    }

    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/admin/comercial?${queryParams}`)
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const createComercial = (comercialData) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/admin/comercial`, {
        method: "POST",
        body: JSON.stringify(comercialData),
        headers: { "Content-Type": "application/json","Accept":"application/json" },
      })
        .then(r => {
          if(r.ok) {
            return r.json()
          }
          reject()
        })
        .then(response => resolve(response.id))
        .catch(reject)
    })
  }

  const updateComercial = (comercialData) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/admin/comercial/${comercialData.username}`, {
        method: "PUT",
        body: JSON.stringify(comercialData),
        headers: { "Content-Type": "application/json","Accept":"application/json" },
      })
        .then(r => {
          if(r.ok) {
            return r.json()
          }
          reject()
        })
        .then(response => resolve(response.id))
        .catch(reject)
    })
  }

  const eliminarAdminComercial = (comercialUsername) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/admin/comercial/${comercialUsername}`, {
        method: "DELETE",
      })
        .then(r => {
          if(r.ok) {
            return r.json()
          }
          reject()
        })
        .then(response => resolve(response.id))
        .catch(reject)
    })
  }

  const createComercialSecret = (comercialUsername) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/comercial/${comercialUsername}/secret`, {
        method: "POST",
        headers: { "Content-Type": "application/json","Accept":"application/json" },
      })
        .then(r => {
          if(r.ok) {
            return r.json()
          }
          reject()
        })
        .then(response => resolve(response.id))
        .catch(reject)
    })
  }

  const crearVisita = (comercialUsername, visitaData) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/comercial/${comercialUsername}/visita`, {
        method: "POST",
        body: JSON.stringify(visitaData),
        headers: { "Content-Type": "application/json","Accept":"application/json" },
      })
        .then(r => r.json())
        .then(response => resolve(response.id))
        .catch(reject)
    })
  }

  const editarClienteVisita = (visitaId, clienteData) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/visita/${visitaId}/cliente`, {
        method: "PUT",
        body: JSON.stringify(clienteData),
        headers: { "Content-Type": "application/json","Accept":"application/json" },
      })
        .then(r => r.json())
        .then(response => resolve(response.id))
        .catch(reject)
    })
  }

  const obtenerProductos = (serie) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/producto/${serie}`)
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const anadirProducto = (visitaId, productoId, materialesPromocionesData) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/visita/${visitaId}/producto/${productoId}`, {
        method: "PATCH",
        body: JSON.stringify(materialesPromocionesData),
        headers: { "Content-Type": "application/json","Accept":"application/json" }
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const anadirSerie = (visitaId, serie, materialesPromocionesData) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/visita/${visitaId}/serie/${serie}`, {
        method: "PATCH",
        body: JSON.stringify(materialesPromocionesData),
        headers: {"Content-Type": "application/json", "Accept": "application/json"}
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const eliminarProducto = (visitaId, productoId) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/visita/${visitaId}/producto/${productoId}`, {
        method: "DELETE"
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const eliminarSerie = (visitaId, serie) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/visita/${visitaId}/serie/${serie}`, {
        method: "DELETE"
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const obtenerSeries = () => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/admin/producto`)
        .then(r => r.json())
        .then(response => {
          const excludedSeries = [
            // "albarracin",
            // "calacatta",
            // "grassi",
            // "ibiza",
            // "borgogna",
            // "renowood",
            // "rustique",
            // "urban",
            // "vernia"
          ]
          const seriesMap = new Map()
          response.filter(p => p.articuloId !== null).forEach(p => {
            if (!seriesMap.has(p.serie))
              seriesMap.set(p.serie, {
                imagen: p.imagen,
                marca: p.marca,
                nombre: p.serie
              })
          })
          const series = {}
          seriesMap.forEach((v, k) => {
            if (excludedSeries.indexOf(k.toLowerCase()) > -1) return;
            series[k] = v
          })
          resolve(series)
        })
        .catch(reject)
    })
  }

  const sendMailVisita = (visitaId) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/visita/${visitaId}/mail`, {
        method: "POST"
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const eliminaVisita = (visitaId) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/visita/${visitaId}`, {
        method: "DELETE"
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const finalizarVisita = (visitaId) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/visita/${visitaId}/finish`, {
        method: "POST"
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  const guardarNotasVisita = (visitaId, notas) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/visita/${visitaId}/notas`, {
        method: "PATCH",
        body: JSON.stringify({notas}),
        headers: {"Content-Type": "application/json", "Accept": "application/json"}
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  return (
    <Router>
      <Switch>
        <Route path="/admin/login">
          <AdminLoginPage/>
        </Route>
        <RouteGuard adminRoute path="/admin/visitas">
          <AdminVisitasPage obtenerAdminVisitas={obtenerAdminVisitas} eliminarAdminVisitas={eliminarAdminVisitas} descargarVisitasCsv={descargarVisitasCsv}/>
        </RouteGuard>
        <RouteGuard adminRoute path="/admin/comerciales">
          <AdminComercialesPage obtenerAdminComerciales={obtenerAdminComerciales} createComercial={createComercial} updateComercial={updateComercial} eliminarAdminComercial={eliminarAdminComercial}/>
        </RouteGuard>
        <RouteGuard adminRoute path="/admin/productos">
          <AdminProductosPage obtenerAdminProductos={obtenerAdminProductos}/>
        </RouteGuard>
        <RouteGuard adminRoute path="/admin">
          <AdminPage/>
        </RouteGuard>
        <Route path="/seleccion-serie">
          <SeleccionSeriePage obtenerSeries={obtenerSeries} />
        </Route>
        <Route path="/app-login/:secret">
          <Layout shouldNotDisplayBackButton={true} largeLogo={true}>
            <AppLoginPage obtenerComercialPorSecreto={obtenerComercialPorSecreto}/>
          </Layout>
        </Route>
        <Route path="/app-login">
          <Layout shouldNotDisplayBackButton={true} largeLogo={true}>
            <AppLoginPage obtenerComercialPorSecreto={obtenerComercialPorSecreto}/>
          </Layout>
        </Route>
        <Route path="/visita/producto/:serie">
          <AnadirProductoPage obtenerProductos={obtenerProductos} anadirProducto={anadirProducto} anadirSerie={anadirSerie}/>
        </Route>
        <Route path="/clientes/visita/:visitaId">
          <StaticVisitaPage obtenerStaticVisita={obtenerStaticVisita}/>
        </Route>
        <Route path="/:comercialUsername/crear-visita">
          <Layout>
            <CrearVisitaPage crearVisita={crearVisita} />
          </Layout>
        </Route>
        <Route path="/:comercialUsername/visita/:visitaId">
          <Layout>
            <VisitaPage obtenerVisita={obtenerVisita} eliminarProducto={eliminarProducto} eliminarSerie={eliminarSerie} finalizarVisita={finalizarVisita} guardarNotasVisita={guardarNotasVisita} editarClienteVisita={editarClienteVisita}/>
          </Layout>
        </Route>
        <Route path="/test">
          <Layout shouldNotDisplayBackButton={true} largeLogo={true}>
            <AppLoginPage obtenerComercialPorSecreto={obtenerComercialPorSecreto}/>
          </Layout>
        </Route>
        <Route path="/:comercialUsername">
          <Layout shouldNotDisplayBackButton={true}>
            <HomePage obtenerVisitas={obtenerVisitas} obtenerComercial={obtenerComercial} createComercialSecret={createComercialSecret} sendMailVisita={sendMailVisita} eliminaVisita={eliminaVisita}/>
          </Layout>
        </Route>
        <Route path="/">
          <LandingPage/>
        </Route>
        <Route path="*" >
          <Layout>
            <NotFoundPage />
          </Layout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
