import React from "react";

function LandingPage() {

  return (
    <div className={"container mx-auto h-screen p-4"}>
      <div className={"h-full w-full flex flex-col justify-center items-center"}>
        <img src={"/assets/images/logo.svg"} alt="logo" className={"w-4/5 md:w-3/5 lg:w-2/5"}/>
      </div>
      <a href="https://wishlist.tauceramica.com/visita/producto/belcastel?param=true">Link</a>
    </div>
  );
}

export default LandingPage;