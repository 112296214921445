import React, { useState } from "react"
import { HiTrash } from "react-icons/hi"
import { HiOutlineInformationCircle } from "react-icons/hi2"
import ConfirmDialog from "./confirm-dialog"
import InfoDialog from "./info-dialog"
import { getImageUrl } from "../utils";

const WishlistProduct = ({ product, eliminaProducto, eliminaSerie, staticVisit }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false)

  const handleDelete = () => {
    if (product.discriminator === "producto") {
      eliminaProducto(product.id)
    } else {
      eliminaSerie(product.nombre)
    }
  }

  return (
    <div className={"grid grid-cols-1 sm:grid-cols-8 py-5 px-2"}>
      {product.discriminator === "producto" &&
        <>
          <div
            className={
              `col-span-1 sm:col-span-${staticVisit ? "4" : "3"} px-4 flex flex-col justify-center items-center my-2 sm:my-0 relative`
            }
          >
            <img
              className=" object-cover rounded-full shadow-drop-bottom h-20 md:h-20 w-full"
              src={getImageUrl(product)}
              alt={product.color}
            />

            {!staticVisit && (
              <div role={"button"} tabIndex={-1}
                className={
                  "w-8 h-8 md:w-6 md:h-6 rounded-full flex justify-center items-center bg-black text-white absolute top-2 right-10"
                }
                onClick={() => setConfirmOpen(true)}
                onKeyDown={() => setConfirmOpen(true)}
              >
                <HiTrash className={"text-xl md:text-lg"} />
              </div>
            )}

            {!staticVisit && (product.extra.camiones || product.extra.palets || product.extra.metros2 || product.extra.promociones) && (
              <div role={"button"} tabIndex={-1}
                className={
                  "w-8 h-8 md:w-6 md:h-6 rounded-full flex justify-center items-center bg-black text-white absolute top-2 left-10"
                }
                onClick={() => setAdditionalInfoOpen(true)}
                onKeyDown={() => setAdditionalInfoOpen(true)}
              >
                <HiOutlineInformationCircle className={"text-xl md:text-lg"} />
              </div>
            )}

          </div>
          <div
            className={
              `col-span-1 sm:col-span-${staticVisit ? "4" : "3"} px-4 flex flex-col justify-center my-2 sm:my-0`
            }
          >
            <div className={"flex flex-col justify-center my-1"}>
              <span
                className={
                  "text-lg font-medium leading-tight text-center text-gray-800 uppercase cursor-default"
                }
              >
                {product.serie} - {product.color}
              </span>
              <span
                className={
                  "text-sm font-light leading-tight text-center text-gray-800 uppercase cursor-default"
                }
              >
                {product.marca}
              </span>
            </div>
            <div className={"flex flex-row justify-center my-1"}>
              <span
                className={
                  "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-full text-gray-800 text-sm uppercase cursor-default"
                }
              >
                {product.formato}
              </span>

              { product.acabado && (
                <span
                  className={
                    "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-full text-gray-800 text-sm uppercase cursor-default"
                  }
                >
                  {product.acabado}
                </span>
              )}

            </div>
          </div>
        </>
      }
      {product.discriminator === "serie" &&
        <div
          className={
            `col-span-1 sm:col-span-3${staticVisit ? "4" : "3"} px-4 flex flex-col justify-center my-2 sm:my-0`
          }
        >
          <div className={"flex flex-row justify-around my-2 sm:my-0"}>
            {!staticVisit && (product.extra.camiones || product.extra.palets || product.extra.metros2 || product.extra.promociones) && (
              <div role={"button"} tabIndex={-1}
                className={
                  "w-8 h-8 md:w-6 md:h-6 rounded-full flex justify-center items-center bg-black text-white"
                }
                onClick={() => setAdditionalInfoOpen(true)}
                onKeyDown={() => setAdditionalInfoOpen(true)}
              >
                <HiOutlineInformationCircle className={"text-xl md:text-lg"} />
              </div>
            )}
            {!staticVisit && (
              <div role={"button"} tabIndex={-1}
                className={
                  "w-8 h-8 md:w-6 md:h-6 rounded-full flex justify-center items-center bg-black text-white"
                }
                onClick={() => setConfirmOpen(true)}
                onKeyDown={() => setConfirmOpen(true)}
              >
                <HiTrash className={"text-xl md:text-lg"} />
              </div>
            )}
          </div>

          <div className={"flex flex-col justify-center my-1"}>
            <span
              className={
                "text-lg font-medium leading-tight text-center text-gray-800 uppercase cursor-default"
              }
            >
              {product.nombre} - completa
            </span>
            <span
              className={
                "text-sm font-light leading-tight text-center text-gray-800 uppercase cursor-default"
              }
            >
              {product.marca}
            </span>
          </div>
        </div>
      }
      <ConfirmDialog
        title="Eliminar producto"
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleDelete}
      >
        ¿Está seguro de eliminar el producto de la wishlist?
      </ConfirmDialog>
      <InfoDialog
        title="Información adicional"
        open={additionalInfoOpen}
        onClose={() => setAdditionalInfoOpen(false)}
      >
        <div className={"flex flex-col justify-center items-center my-2"}>
          {(product.extra.camiones || product.extra.palets || product.extra.metros2) && (
            <div className={"w-fit pt-2 pb-3 px-2"}>
              {product.extra.camiones &&
              <div className={"grid grid-cols-2 py-2 px-5"}>
                <span
                  className={"w-fit font-medium text-lg text-left text-gray-800 cursor-default"}>Camiones:</span>
                <span
                  className={"text-lg text-right text-gray-800 cursor-default"}>{product.extra.camiones}</span>
              </div>
              }
              {product.extra.palets &&
              <div className={"grid grid-cols-2 py-2 px-5"}>
                <span
                  className={"w-fit font-medium text-lg text-left text-gray-800 cursor-default"}>Palets:</span>
                <span
                  className={"text-lg text-right text-gray-800 cursor-default"}>{product.extra.palets}</span>
              </div>
              }
              {product.extra.metros2 &&
              <div className={"grid grid-cols-2 py-2 px-5"}>
                <span
                  className={"w-fit font-medium text-lg text-left text-gray-800 cursor-default"}>Metros&#178;:</span>
                <span
                  className={"text-lg text-right text-gray-800 cursor-default"}>{product.extra.metros2}</span>
              </div>
              }
            </div>
          )}
          {product.extra.promociones &&
          <div className={"flex flex-col justify-center pt-5"}>
            <span className={"text-lg font-medium text-center text-gray-800 cursor-default mb-2"}>Promociones</span>
            <span
              className={"w-max-prose text-lg text-center text-gray-800 cursor-default"}>{product.extra.promociones}</span>
          </div>
          }
        </div>
      </InfoDialog>
    </div>
  )
}

export default WishlistProduct
