import React from "react";
import { getImageUrl } from "../../../utils";

const ConfirmarSeleccion = ({serieCompletaSeleccionado, serie, coloresSeleccionados, formatosSeleccionados, acabadosSeleccionados, materialesPromocionesData, onConfirmacion}) => {
  return (serieCompletaSeleccionado || (coloresSeleccionados.length > 0 && formatosSeleccionados.length > 0 && acabadosSeleccionados.length > 0)) && (
    <div>
      <div className={"relative grid grid-cols-1 my-4"}>
        <div className={"flex flex-row justify-center my-5 px-2"}>
          <button
            className={"w-3/4 px-4 py-3 rounded-full border-2 border-black bg-white text-black text-bold text-sm tracking-widest shadow-drop-bottom uppercase"}
            onClick={onConfirmacion}
          >
            Añadir
          </button>
        </div>
      </div>
      <div className={"grid grid-cols-1 py-5 px-2"}>
        {!serieCompletaSeleccionado && coloresSeleccionados.map((colorSeleccionado, colorIndex) =>
          formatosSeleccionados[colorIndex] && formatosSeleccionados[colorIndex].map((formato, formatoIndex) =>
            acabadosSeleccionados[colorIndex] && acabadosSeleccionados[colorIndex][formatoIndex] && acabadosSeleccionados[colorIndex][formatoIndex].map((acabado, acabadoIndex) =>
              <>
                <div key={colorSeleccionado.imagen} className={"col-span-1 sm:col-span-3 px-4 flex flex-col justify-center items-center my-3 sm:my-2"}>
                  <img className=" object-cover rounded-full shadow-drop-bottom h-32 max-w-lg w-full"
                    src={getImageUrl(colorSeleccionado)}
                    alt={colorSeleccionado.color}/>
                </div>
                <div className={"flex flex-col justify-center my-2"}>
                  <span
                    className={"text-lg font-medium leading-tight text-center text-gray-800 uppercase cursor-default"}>{serie} - {colorSeleccionado.color}</span>
                </div>
                <div className={"flex flex-wrap flex-row justify-center my-2 max-w-full"}>
                  <span key={colorIndex+formatoIndex+acabadoIndex+formato}
                    className={"px-3 py-1 my-1 mx-2 border border-gray-400 rounded-full text-gray-800 text-sm uppercase cursor-default"}>{formato}</span>
                  {acabado && (
                    <span key={colorIndex+formatoIndex+acabadoIndex+acabado}
                      className={"px-3 py-1 my-1 mx-2 border border-gray-400 rounded-full text-gray-800 text-sm uppercase cursor-default"}>{acabado}</span>
                  )}
                </div>
              </>
            )
          )
        )}
        <div className={"col-span-1 sm:col-span-3 px-4 flex flex-col justify-center my-3 sm:my-2"}>
          {serieCompletaSeleccionado &&
            <div className={"flex flex-col justify-center my-2"}>
              <span
                className={"text-lg font-medium leading-tight text-center text-gray-800 uppercase cursor-default"}>{serie} - completa</span>
            </div>
          }
          <div className={"flex flex-col justify-center items-center my-2"}>
            {(materialesPromocionesData.camiones || materialesPromocionesData.palets || materialesPromocionesData.metros2) && (
              <div className={"w-fit pt-2 pb-3 px-2"}>
                {materialesPromocionesData.camiones &&
                <div className={"grid grid-cols-2 py-2 px-5"}>
                  <span
                    className={"w-fit font-medium text-lg text-left text-gray-800 cursor-default"}>Camiones:</span>
                  <span
                    className={"text-lg text-right text-gray-800 cursor-default"}>{materialesPromocionesData.camiones}</span>
                </div>
                }
                {materialesPromocionesData.palets &&
                <div className={"grid grid-cols-2 py-2 px-5"}>
                  <span
                    className={"w-fit font-medium text-lg text-left text-gray-800 cursor-default"}>Palets:</span>
                  <span
                    className={"text-lg text-right text-gray-800 cursor-default"}>{materialesPromocionesData.palets}</span>
                </div>
                }
                {materialesPromocionesData.metros2 &&
                <div className={"grid grid-cols-2 py-2 px-5"}>
                  <span
                    className={"w-fit font-medium text-lg text-left text-gray-800 cursor-default"}>Metros&#178;:</span>
                  <span
                    className={"text-lg text-right text-gray-800 cursor-default"}>{materialesPromocionesData.metros2}</span>
                </div>
                }
              </div>
            )}
            {materialesPromocionesData.promociones &&
              <div className={"flex flex-col justify-center py-4"}>
                <span className={"text-lg font-medium text-center text-gray-800 cursor-default mb-2"}>Promociones</span>
                <span
                  className={"w-max-prose text-lg text-center text-gray-800 cursor-default"}>{materialesPromocionesData.promociones}</span>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmarSeleccion;