import React from "react";
import { Capore } from "./capore/capore.js"
import { ClientAppMqttClient } from "./client-app-mqtt-client.js";

class ScapeXMobile extends React.Component {

  constructor(props) {
    super(props);

    this.displayName = "";

    this.state = { connectedToTable: false, sxmInitialized: false }
    this.buttonText = "Connect to Table"
  }

  initSXM = () => {
    if (this.state.sxmInitialized) return;
    this.buttonText = "Connecting..."
    this.capore = new Capore();
    this.capore.registerCallback(this);
    this.setState({ sxmInitialized: true });
    this.appMqttClient = new ClientAppMqttClient(this, this.capore.room_uuid, this.capore.uuid);
    this.capore.connect();
  }

  render() {
    return (<div className={"grid-cols-1 pt-3 pb-2"}>
      <div className={"flex flex-row justify-center my-5"}>
        <div role={"button"} tabIndex={-1}
             className={
               (this.state.sxmInitialized ? "bg-gray-800" : "bg-black") + " h-12 md:h-10 rounded-full flex justify-center items-center text-white px-3"
             }
             onClick={this.initSXM}
             onKeyDown={this.initSXM}
        >
          <span className={"text-lg"}>{this.buttonText}</span>
        </div>
      </div>
    </div>)
  }
}

export default ScapeXMobile