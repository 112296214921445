import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";

function AppLoginPage({ obtenerComercialPorSecreto }) {
  const [initialized, setInitialized] = useState(false)
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    password: "",
  })

  const [formErrors, setFormErrors] = useState({
    password: null,
  })

  const { secret } = useParams()

  useEffect(() => {
    const username = localStorage.getItem("tau-current-comercial")
    if (username) {
      window.location.replace(`/${username}`)
    }

    if (secret) {
      obtenerComercialPorSecreto(secret)
        .then((data) => {
          const username = data.username
          console.log("Logged as", username, data)
          localStorage.setItem("tau-current-comercial", username)
          window.location.replace(`/${username}`)
        })
        .catch(() => {
          setInitialized(true)
        })
    } else {
      setInitialized(true)
    }
  }, [])

  const handleLoginSubmit = (e) => {
    e.preventDefault()
    const _formErrors = {
      password: null,
    }

    let error = false

    if (formData.password.length === 0) {
      error = true
      _formErrors.password = "Introduce el secreto"
    }

    setFormErrors(_formErrors)

    if (!error) {
      setLoading(true)
      obtenerComercialPorSecreto(formData.password)
        .then((data) => {
          const username = data.username
          console.log("Logged as", username, data)
          localStorage.setItem("tau-current-comercial", username)
          window.location.replace(`/${username}`)
        })
        .catch(() => {
          setFormErrors({
            username: null,
            password: "Secreto incorrecto"
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return initialized && (
    <div className={"container mx-auto h-screen p-4 max-w-screen-md"}>
      <div className={"w-full flex flex-col justify-center items-center"}>
        <form className={"w-4/5 md:w-3/5 lg:w-1/2 my-5 py-5"}>
          <div className="relative mb-3">
            <input id="password" type="password"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={formData["password"]}
              onChange={e => setFormData({...formData, password: e.target.value})}/>
            <label htmlFor="password"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{"Secreto"}</label>
          </div>
          {formErrors.password && (
            <div className="pl-5 mb-3 text-normal text-red-700">
              {formErrors.password}
            </div>
          )}

          <button
            className={"w-full mt-3 px-4 py-3 rounded-full bg-black text-white text-sm tracking-widest shadow-drop-bottom uppercase"}
            type="submit" onClick={handleLoginSubmit} disabled={loading}>
            <span className={"uppercase"}>{"Empezar"}</span>
          </button>
        </form>

      </div>
    </div>
  );
}

export default AppLoginPage;