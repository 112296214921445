import React from "react"

import Header from "./header"

const Layout = ({ shouldNotDisplayBackButton, children, largeLogo, onBack }) => {
  return (
    <div className={"container mx-auto p-4"}>
      <Header shouldNotDisplayBackButton={shouldNotDisplayBackButton} largeLogo={largeLogo} onBack={onBack} />
      {children}
    </div>
  )
}

export default Layout
