import React from "react";
import { getImageUrl } from "../../../utils";
import {HiChevronDoubleRight} from "react-icons/hi";

const ElegirAcabado = ({nextStep, acabados, onAcabadoSeleccionado, coloresSeleccionados, formatosSeleccionados, acabadosSeleccionados}) => {

  //const isContinueEnabled = acabadosSeleccionados.length === coloresSeleccionados.length && acabadosSeleccionados.filter(as => as.length > 0).length === coloresSeleccionados.length
  //console.log("isContinueEnabled", isContinueEnabled)
  //console.log("coloresSeleccionados.length", coloresSeleccionados.length)
  //console.log("acabadosSeleccionados.length", acabadosSeleccionados.length)
  //console.log("acabadosSeleccionados.filter(as => as.length > 0).length", acabadosSeleccionados.filter(as => as.length > 0).length)

  // const isContinueEnabled =
  //   acabadosSeleccionados.length === coloresSeleccionados.length &&
  //   acabadosSeleccionados.every( (as, asIndex) => {
  //     return as.length === formatosSeleccionados[asIndex].length &&
  //       as.every(asColor => asColor.length > 0)
  //   })

  const isContinueEnabled = acabadosSeleccionados.length === coloresSeleccionados.length && acabadosSeleccionados.filter(fs => fs.length > 0).length === coloresSeleccionados.length
  return (
    <div className={"flex flex-col justify-center"}>
      <h2 className="text-center text-2xl text-bold uppercase tracking-wide my-3">Elegir acabado</h2>
      <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-2 md:gap-x-5 xl:gap-x-8 mb-3"}>
        {coloresSeleccionados.map((color, colorIndex) => (
          formatosSeleccionados[colorIndex] && formatosSeleccionados[colorIndex].map((formato, formatoIndex) =>
            <>
              <div className={"flex flex-col justify-center my-3 mt-5"} key={color.imagen} role={"button"} tabIndex={-1}>
                <img className="h-32 md:h-20 w-full object-cover rounded-full shadow-drop-bottom"
                  src={getImageUrl(color)} alt={color.color}/>
                <span className={"mt-2 text-gray-700 text-md text-center uppercase"}>{color.color} - {formato}</span>
              </div>
              <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-2 md:gap-x-5 xl:gap-x-8 mb-3"}>
                {acabados[colorIndex] && acabados[colorIndex][formatoIndex] && acabados[colorIndex][formatoIndex].sort((a,b) => a > b ? 1 : -1).map(acabado => (
                  <button type="button" key={acabado}
                    className={`mt-3 px-4 py-3 rounded-full text-sm tracking-widest shadow-drop-bottom uppercase ${acabadosSeleccionados[colorIndex] && acabadosSeleccionados[colorIndex][formatoIndex] && acabadosSeleccionados[colorIndex][formatoIndex].indexOf(acabado) > -1 ? "bg-black text-white" : "bg-zinc-400 text-gray-300"}`}
                    onClick={() => {
                      onAcabadoSeleccionado(acabado, colorIndex, formatoIndex)
                    }}>
                    {acabado || "Por defecto"}
                  </button>
                ))}
              </div>
            </>
          )
        ))}
      </div>
      <div className={"grid grid-cols-1 mb-3"}>
        <div className={"flex flex-col justify-center items-center my-3 mt-5"} role={"button"} tabIndex={-1}>
          <button
            className={`flex flex-row justify-center items-center w-3/4 px-4 py-3 rounded-full border-2 text-sm tracking-widest shadow-drop-bottom uppercase ${isContinueEnabled ? "border-black bg-white text-black text-bold" : "border-zinc-400 text-zinc-400"}`}
            onClick={() => {
              isContinueEnabled && nextStep()
            }}
          >
            Siguiente <HiChevronDoubleRight className={"inline ml-2"}/>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ElegirAcabado;