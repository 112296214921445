import React, {useState} from "react";

const adminUsers = [
  "mario",
  "testy",
  "laura"
]

const adminPasswords = [
  "u4SfUdhWk3MqpxZcv6eHbE",
  "GKJP96RXtCvkj8xHmsNQhW",
  "uaM3XrGVpzbJW25g9yRscZ"
]

function AdminLoginPage() {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  })

  const [formErrors, setFormErrors] = useState({
    username: null,
    password: null,
  })

  const handleLoginSubmit = (e) => {
    e.preventDefault()
    const _formErrors = {
      username: null,
      password: null,
    }

    let error = false

    if (formData.username.length === 0) {
      error = true
      _formErrors.username = "Introduzca un usuario correcto"
    }

    if (formData.password.length === 0) {
      error = true
      _formErrors.password = "Introduzca una contraseña"
    }

    setFormErrors(_formErrors)

    if (!error) {
      setLoading(true)
      console.log()
      if (adminUsers.includes(formData.username) && adminPasswords.includes(formData.password)) {
        localStorage.setItem("tau-current-admin", formData.password)
        localStorage.setItem("tau-current-comercial", formData.username)
        window.location.replace("/admin")
      } else {
        setFormErrors({
          username: null,
          password: "Credenciales incorrectas"
        })
      }
      setLoading(false)
    }
  }

  return (
    <div className={"container mx-auto h-screen p-4 max-w-screen-md"}>
      <div className={"w-full flex flex-col justify-center items-center"}>
        <img src={"/assets/images/logo.svg"} alt="logo" className={"w-3/5 md:w-2/5 lg:w-1/5 mt-3"}/>
      </div>
      <div className={"w-full flex flex-col justify-center items-center my-5"}>
        <form className={"w-4/5 md:w-3/5 lg:w-1/2 my-5"}>
          <div className="relative mb-3">
            <input id="username" type="email"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={formData["username"]}
              onChange={e => setFormData({...formData, username: e.target.value})}/>
            <label htmlFor="username"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Usuario</label>
          </div>
          {formErrors.username && (
            <div className="pl-5 mb-3 text-normal text-red-700">
              {formErrors.username}
            </div>
          )}

          <div className="relative mb-3">
            <input id="password" type="password"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={formData["password"]}
              onChange={e => setFormData({...formData, password: e.target.value})}/>
            <label htmlFor="password"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Contraseña</label>
          </div>
          {formErrors.password && (
            <div className="pl-5 mb-3 text-normal text-red-700">
              {formErrors.password}
            </div>
          )}

          <button
            className={"w-full mt-3 px-4 py-3 rounded-full bg-black text-white text-md font-bold shadow-drop-bottom uppercase"}
            type="submit" onClick={handleLoginSubmit} disabled={loading}>
            <span className={"uppercase"}>Empezar</span>
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminLoginPage;