import React from "react";
import { HiChevronDoubleRight } from "react-icons/hi";
import { getImageUrl } from "../../../utils";

const ElegirColor = ({goToStep, nextStep, colors, onColorSeleccionado, onSerieCompletaSeleccionado, coloresSeleccionados}) => {
  const coloresSinDecorarado = colors.filter(c => !c.decorado);
  const coloresConDecorarado = colors.filter(c => c.decorado);
  return (
    <div className={"flex flex-col justify-center"}>
      <div className={"grid grid-cols-1 mb-3"}>
        <div className={"flex flex-col justify-center items-center my-3 mt-5"} role={"button"} tabIndex={-1}>
          <button
            className={"w-3/4 px-4 py-3 rounded-full bg-black text-white text-sm tracking-widest shadow-drop-bottom uppercase"}
            onClick={() => {
              onSerieCompletaSeleccionado()
              goToStep(4)
            }}
          >
            Serie completa
          </button>
        </div>
      </div>
      <h2 className="text-center text-2xl text-bold uppercase tracking-wide my-3">o Elegir Color</h2>
      <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-2 md:gap-x-5 xl:gap-x-8 mb-3"}>
        {coloresSinDecorarado.filter(c => !c.decorado).map(color => (
          <div className={"flex flex-col justify-center my-3 mt-5"} key={color.imagen} role={"button"} tabIndex={-1}
            onClick={() => {
              onColorSeleccionado(color)
              //nextStep()
            }}
            onKeyDown={() => {
              onColorSeleccionado(color)
              //nextStep()
            }}
          >
            <img className={`h-32 md:h-20 w-full object-cover rounded-full shadow-drop-bottom ${coloresSeleccionados.indexOf(color) > -1 ? "scale-110 border-2 border-black" : ""}`}
              src={getImageUrl(color)} alt={color.color}/>
            <span className={`mt-2 text-md text-center uppercase ${coloresSeleccionados.indexOf(color) > -1 ? "font-bold text-black" : "text-gray-700"}`}>{color.color}</span>
          </div>
        ))}
      </div>
      {coloresConDecorarado.length > 0 && (
        <>
          <h2 className="text-center text-2xl text-bold uppercase tracking-wide my-3 mt-5 pt-4">Decorados</h2>
          <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-2 md:gap-x-5 xl:gap-x-8 mb-3"}>
            {coloresConDecorarado.map(color => (
              <div className={"flex flex-col justify-center my-3 mt-5"} key={color.imagen} role={"button"} tabIndex={-1}
                onClick={() => {
                  onColorSeleccionado(color)
                  //nextStep()
                }}
                onKeyDown={() => {
                  onColorSeleccionado(color)
                  //nextStep()
                }}
              >
                <img className={`h-32 md:h-20 w-full object-cover rounded-full shadow-drop-bottom ${coloresSeleccionados.indexOf(color) > -1 ? "scale-110 border-2 border-black" : ""}`}
                  src={getImageUrl(color)} alt={color.color}/>
                <span className={`mt-2 text-md text-center uppercase ${coloresSeleccionados.indexOf(color) > -1 ? "font-bold text-black" : "text-gray-700"}`}>{color.color}</span>
              </div>
            ))}
          </div>
        </>
      )}
      <div className={"flex flex-col justify-center items-center my-3 mt-5"} role={"button"} tabIndex={-1}>
        <button
          className={`flex flex-row justify-center items-center w-3/4 px-4 py-3 rounded-full border-2 text-sm tracking-widest shadow-drop-bottom uppercase ${coloresSeleccionados.length > 0 ? "border-black bg-white text-black text-bold" : "border-zinc-400 text-zinc-400"}`}
          onClick={() => {
            coloresSeleccionados.length > 0 && nextStep()
          }}
        >
          Siguiente <HiChevronDoubleRight className={"inline ml-2"}/>
        </button>
      </div>
    </div>
  )
};

export default ElegirColor;