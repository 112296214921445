import React from "react";

export default function ConfirmDialog(props) {
  const { open, onClose, title, children, onConfirm, si, no } = props
  if (!open) {
    return <></>
  }

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-zinc-500/50 flex">
      <div className="relative p-8 bg-[#F6F6F6] w-5/6 max-w-md m-auto flex-col flex rounded-lg">
        <h2 className="text-xl font-medium leading-tight text-gray-800 uppercase">
          {title}
        </h2>
        <div className="py-5 text-gray-800">{children}</div>
        <div className="flex justify-end">
          <div className="p-1">
            <button
              className={"min-w-min w-20 px-4 py-3 rounded-full bg-black text-white text-sm tracking-widest shadow-drop-bottom uppercase"}
              onClick={() => {
                onClose()
                onConfirm()
              }}
            >
              {si || "Sí"}
            </button>
          </div>
          <div className="p-1">
            <button
              className={"min-w-min w-20 px-4 py-3 rounded-full bg-[#F6F6F6] border-2 border-black text-black text-sm tracking-widest shadow-drop-bottom uppercase"}
              onClick={() => onClose()}
            >
              {no || "No"}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
