import React, { useEffect, useState } from "react";
import {useHistory} from "react-router-dom";
import Header from "../../components/header";

const SeleccionSeriePage = ({obtenerSeries}) => {
  const [loading, setLoading] = useState(false);
  const [seriesData, setSeriesData] = useState({})
  const [filterData, setFilterData] = useState("")
  const [isFiltered, setIsFiltered] = useState(false)
  const [filteredSeries, setFilteredSeries] = useState([])

  const history = useHistory()

  useEffect(() => {
    setLoading(true)
    obtenerSeries()
      .then((series) => {
        setSeriesData(series)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }, [obtenerSeries])

  useEffect(() => {
    setIsFiltered(false)
    const filterSeries = setTimeout(() => {
      setFilteredSeries(Object.keys(seriesData).filter((serie) => {
        if (filterData.length > 0) {
          return serie.toLowerCase().includes(filterData.toLowerCase())
        }
        return false
      }))
      setIsFiltered(true)
    }, 500)
    return () => clearTimeout(filterSeries)
  }, [filterData])

  const goToSerie = (serie) => {
    history.push("/visita/producto/"+serie)
  }

  const onBack = history.goBack

  return (
    <div className={"container mx-auto p-4"}>
      <Header onBack={onBack}/>
      { loading && (
        <div className={"relative h-[70vh] flex flex-col justify-center items-center"}>
          <span className={"my-3 mt-4 text-black text-center text-3xl sm:text-3xl font-regular"}>Cargando series...</span>
        </div>
      )}
      { !loading && seriesData && (
        <div className={"container mx-auto p-4 max-w-screen-md"}>
          <div className={"relative grid grid-cols-1 my-3 mt-5 pt-5 px-8"}>
            <input type={"text"}
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder="Escribe el nombre de la serie"
              value={filterData}
              onChange={e => setFilterData(e.target.value)}/>
          </div>
          <div className={"relative grid grid-cols-1 my-3 mt-5 pt-5 px-8"}>
            {filteredSeries.sort((s1, s2) => s1 > s2 ? 1 : -1).map(serie => (
              <div className={"grid grid-cols-3 my-3"} key={serie} onClick={() => goToSerie(serie)} onKeyUp={() => goToSerie(serie)} role={"button"} tabIndex={-1}>
                <img className={"h-20 w-20 object-cover rounded-full shadow-drop-bottom"}
                  src={seriesData[serie].imagen} alt={`${seriesData[serie].imagen} - ${seriesData[serie].marca}`}/>
                <div className={"w-full flex flex-col justify-center col-span-2 px-5"}>
                  <span className={"text-xl text-center uppercase text-black"}>
                    {serie}
                  </span>
                  <span className={"text-sm text-center uppercase text-gray-800 font-light"}>
                    {seriesData[serie].marca}
                  </span>
                </div>
              </div>
            )) }

            {filteredSeries.length === 0 && filterData.length === 0 && (
              <span className={"my-3 mt-4 text-center text-xl sm:text-xl"}>Escribe el nombre de la serie para mostrar las coincidentes.</span>
            )}

            {filteredSeries.length === 0 && isFiltered && filterData.length > 0 && (
              <span className={"my-3 mt-4 text-center text-xl sm:text-xl"}>No hay series que coincidan con tu búsqueda.</span>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default SeleccionSeriePage;
