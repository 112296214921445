import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { HiArrowLeft } from "react-icons/hi"
import ConfirmDialog from "./confirm-dialog";

const Header = ({ shouldNotDisplayBackButton, onBack, largeLogo }) => {
  const [confirmOpen, setConfirmOpen] = useState(null)
  const [comercialUsernameState, setComercialUsername] = useState(null)

  const history = useHistory()
  const { comercialUsername } = useParams()

  useEffect(() => {
    if (comercialUsername) {
      setComercialUsername(comercialUsername)
    } else {
      const comercialUsernameFromLocal = localStorage.getItem("tau-current-comercial")
      if (comercialUsernameFromLocal) {
        setComercialUsername(comercialUsernameFromLocal)
      }
    }
  }, [comercialUsername])

  const handleOnBack = () => {
    if (onBack) return onBack()
    if (comercialUsernameState) {
      history.push(`/${comercialUsernameState}`)
    }
  }

  const handleToHome = () => {
    if (comercialUsernameState) {
      history.push(`/${comercialUsernameState}`)
    }
  }

  const handleLogout = () => {
    localStorage.setItem("tau-current-comercial", "")
    localStorage.setItem("tau-current-admin", "")
    window.location.replace("/app-login")
  }

  return (
    <div className={"grid grid-cols-6 mt-3 mb-5"}>
      <div className={"col-span-1 flex flex-row justify-center items-center"}>
        {!shouldNotDisplayBackButton && (
          <button onClick={handleOnBack}>
            <HiArrowLeft className={"text-3xl"} />
          </button>
        )}
      </div>
      <div className={"col-span-4 flex flex-row justify-center items-center"}>
        <div role="button" tabIndex={-99}
          onClick={handleToHome}
          onKeyDown={handleToHome}
          className={"flex flex-row justify-center"}
        >
          <img src={"/assets/images/logo.svg"} alt="logo" className={largeLogo ? "w-3/5" : "w-1/2"} />
        </div>
      </div>
      <div
        role="button" tabIndex={-99}
        onClick={() => comercialUsernameState && setConfirmOpen(true)}
        onKeyDown={() => comercialUsernameState && setConfirmOpen(true)}
        className={"col-span-1 flex flex-row justify-end items-center"}>
        {comercialUsernameState && (
          <span>{comercialUsernameState}</span>
        )}
      </div>
      <ConfirmDialog
        title="Cerrar sesión"
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleLogout}
      >
        <span>¿Está seguro de cerrar sesión? </span>
      </ConfirmDialog>
    </div>
  )
}

export default Header
