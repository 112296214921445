import React from "react";

export default function InfoDialog(props) {
  const { open, onClose, title, children, disabled, okCaption } = props
  if (!open) {
    return <></>
  }

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-zinc-500/50 flex">
      <div className="relative p-8 bg-[#F6F6F6] w-5/6 max-w-md m-auto flex-col flex rounded-lg">
        <h2 className="text-2xl font-medium leading-tight text-center text-gray-800 uppercase">
          {title}
        </h2>
        <div className="py-5 text-gray-800">{children}</div>
        <div className="flex justify-end">
          <div className="p-1">
            <button
              disabled={disabled}
              className={(disabled ? "bg-[#F6F6F6]" : "bg-black") + " min-w-min w-20 px-4 py-3 rounded-full text-white text-sm tracking-widest shadow-drop-bottom uppercase"}
              onClick={() => {
                onClose()
              }}
            >
              { okCaption || "Ok"}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
