import React, { useState } from "react";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment"

const CrearVisitaPage = ({crearVisita}) => {
  const [formData, setFormData] = useState({
    cliente: "",
    correo: "",
    esCliente: null,
    fecha_creacion: moment().format("YYYY-MM-DD")
  })

  const [formErrors, setFormErrors] = useState({
    cliente: null,
    correo: null,
    esCliente: null,
    fecha_creacion:null
  })

  const history = useHistory()
  const { comercialUsername } = useParams()

  const handleNewList = () => {
    let isErrored = false
    let _formErrors = {
      cliente: null,
      correo: null,
      esCliente: null,
      fecha_creacion:null
    }

    if (!formData.cliente || formData.cliente.length < 1) {
      _formErrors.cliente = {
        message: "Debe informar el campo"
      }
      isErrored = true
    }

    if (!formData.correo || formData.correo.length < 1) {
      _formErrors.correo = {
        message: "Debe informar el campo"
      }
      isErrored = true
    //eslint-disable-next-line
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.correo))) {
      _formErrors.correo = {
        message: "La dirección no es correcta"
      }
      isErrored = true
    }

    if (formData.esCliente === null) {
      _formErrors.esCliente = {
        message: "Debe informar el campo"
      }
      isErrored = true
    }

    setFormErrors(_formErrors)
    if (!isErrored) {
      crearVisita(comercialUsername, formData)
        .then((visitaId) => {
          setFormData({
            cliente: "",
            correo: "",
            esCliente: null,
            fecha_creacion: moment().format("YYYY-MM-DD")
          })
          history.push(`/${comercialUsername}/visita/${visitaId}`)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }

  return (
    <div className={"container mx-auto p-4 max-w-screen-sm"}>
      <div className={"grid grid-cols-1 mb-5"}>
        <div className={"d-flex justify-content-center"}>
          <h2 className="text-2xl md:text-3xl font-medium leading-tight text-black uppercase">Nueva visita</h2>
        </div>
      </div>
      <div className={"grid grid-cols-1 mb-3"}>
        <div className={"d-flex justify-content-center"}>
          <form className={"w-100"}>
            <div className="relative mb-3">
              <input id="cliente" type="text"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={formData["cliente"]}
                onChange={e => setFormData({...formData, cliente: e.target.value})}/>
              <label htmlFor="cliente"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Nombre del cliente</label>
            </div>
            {formErrors.cliente && (
              <div className="pl-5 mb-3 text-normal text-red-700">
                {formErrors.cliente.message}
              </div>
            )}

            <div className="relative mb-3">
              <input id="correo-electronico" type="email"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={formData["correo"]}
                onChange={e => setFormData({...formData, "correo": e.target.value})}/>
              <label htmlFor="correo-electronico"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Correo electrónico del cliente</label>
            </div>
            {formErrors.correo && (
              <div className="pl-5 mb-3 text-normal text-red-700">
                {formErrors.correo.message}
              </div>
            )}

            <div className="relative mb-3">
              <input id="fecha_creacion" type="date"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                value={formData["fecha_creacion"]}
                onChange={e => setFormData({...formData, "fecha_creacion": e.target.value})}/>
              <label htmlFor="fecha_creacion"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Fecha</label>
            </div>
            {formErrors.fecha && (
              <div className="pl-5 mb-3 text-normal text-red-700">
                {formErrors.fecha.message}
              </div>
            )}

            <div className="relative mb-3">
              <select id="es-cliente"
                className={`tau-es-cliente block px-2.5 pb-2.5 pt-4 w-full text-sm bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black ${formData.esCliente !==  null ? "text-black" : "text-gray-500"}`}
                onChange={e => setFormData({...formData, "esCliente": e.target.value === "si" ? true : e.target.value === "no" ? false : null})}
                value={null}
              >
                <option value={null}>¿Es cliente?</option>
                <option value={"si"}>Sí</option>
                <option value={"no"}>No</option>
              </select>
            </div>
            {formErrors.esCliente && (
              <div className="pl-5 mb-3 text-normal text-red-700">
                {formErrors.esCliente.message}
              </div>
            )}


            <button
              className={"mt-3 px-4 py-3 rounded-full bg-black text-white text-sm tracking-widest shadow-drop-bottom uppercase"}
              type="button" onClick={handleNewList}>
              Crear nueva visita
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CrearVisitaPage;