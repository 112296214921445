import React, {useState, useEffect} from "react";
import {useParams, useHistory} from "react-router-dom";
import StepWizard from "react-step-wizard";
import { getImageUrl } from "../../utils";
import Header from "../../components/header";
import usePageVisibility from "../../hooks/usePageVisibility";
import { HiOutlineInformationCircle } from "react-icons/hi";

// Steps
import ElegirColor from "./steps/elegir-color";
import ElegirFormato from "./steps/elegir-formato";
import ElegirAcabado from "./steps/elegir-acabado";
import ElegirMaterialesPromociones from "./steps/elegir-materiales-promociones";
import ConfirmarSeleccion from "./steps/confirmar-seleccion";
import InfoDialog from "../../components/info-dialog";

// eslint-disable-next-line
const AnadirProductoPage = ({anadirProducto, anadirSerie, obtenerProductos}) => {
  const [isVisitaSet, setIsVisitaSet] = useState(false)
  const [productos, setProductos] = useState([])
  const [colores, setColores] = useState([])
  const [formatos, setFormatos] = useState([])
  const [acabados, setAcabados] = useState([])
  const [coloresSeleccionados, setColoresSeleccionados] = useState([])
  const [formatosSeleccionados, setFormatosSeleccionados] = useState([])
  const [acabadosSeleccionados, setAcabadosSeleccionados] = useState([])
  const [serieCompletaSeleccionado, setSerieCompletaSeleccionado] = useState(false)
  const [materialesPromocionesData, setMaterialesPromocionesData] = useState({
    camiones: null,
    palets: null,
    metros2: null,
    promociones: ""
  })

  const [wizardInstance, setWizardInstance] = useState(null)
  const [shouldNotDisplayBackButton, setShouldNotDisplayBackButton] = useState(true)

  const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false)

  const history = useHistory()
  const {serie} = useParams()

  const pageVisibility = usePageVisibility();

  useEffect(() => {
    const visitaId = localStorage.getItem("tau-pamesa-current-visita")
    setIsVisitaSet(!!visitaId && visitaId != -1)
  }, [pageVisibility])

  useEffect(() => {
    obtenerProductos(serie)
      .then((productos) => {
        setProductos(productos)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [obtenerProductos, serie])

  useEffect(() => {
    const colors = productos.map(p => ({
      color: p.color,
      imagen: p.imagen,
      decorado: p.decorado
    }))
    const presentColors = []
    setColores(colors.filter((c) => {
      const colorKey = c.color + (c.decorado ? "1" : "0")
      if (presentColors.indexOf(colorKey) === -1) {
        presentColors.push(colorKey)
        return true;
      }
      return false
    }))
  }, [productos])

  useEffect(() => {
    if (coloresSeleccionados.length === 0) {
      setFormatos([])
      setFormatosSeleccionados([])
    } else {
      setSerieCompletaSeleccionado(false)
      const todosFormatos = coloresSeleccionados.map(c => productos.filter(p => p.color == c.color && p.decorado == c.decorado).map(p => p.formato))
      // eslint-disable-next-line
      const presentFormatos = todosFormatos.map(f => [])
      setFormatos(todosFormatos.map((fc, index) =>
        fc.filter((f) => {
          if (presentFormatos[index].indexOf(f) === -1) {
            presentFormatos[index].push(f)
            return true;
          }
          return false
        })
      ))
    }
  }, [productos, coloresSeleccionados])

  useEffect(() => {
    if (formatosSeleccionados.length === 0) {
      setAcabados([])
      setAcabadosSeleccionados([])
    } else {
      const todosAcabados = coloresSeleccionados.map((c, colorIndex) => formatosSeleccionados[colorIndex] ? formatosSeleccionados[colorIndex].map(f => productos.filter(p => p.color == c.color && p.decorado == c.decorado && p.formato == f).map(c => c.acabado.toLowerCase())) : [])
      // eslint-disable-next-line
      const presentAcabados = todosAcabados.map(ac => ac.map(af => []))
      setAcabados(todosAcabados.map((ac, indexAC) => {
        return ac.map((af, indexAF) => {
          return af.filter((a) => {
            if (presentAcabados[indexAC][indexAF].indexOf(a) === -1) {
              presentAcabados[indexAC][indexAF].push(a)
              return true;
            }
            return false
          })
        })
      }))
    }
  }, [productos, formatosSeleccionados, coloresSeleccionados])

  const onBack = () => {
    if (shouldNotDisplayBackButton) {
      const visitaId = localStorage.getItem("tau-pamesa-current-visita")
      const comercialUsername = localStorage.getItem("tau-current-comercial")
      if (visitaId > -1 && comercialUsername) {
        history.replace(`/${comercialUsername}/visita/${visitaId}`)
      } else {
        window.location.replace("/app-login")
      }
    } else {
      wizardInstance && wizardInstance.previousStep()
    }
  }

  const onStepChange = (stats) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    setShouldNotDisplayBackButton(stats.activeStep === 1)
  }

  const toggleColorSeleccionado = (color) => {
    const colorIndex = coloresSeleccionados.indexOf(color)
    if (colorIndex > -1) {
      const targetImage = coloresSeleccionados[colorIndex].imagen
      setColoresSeleccionados(coloresSeleccionados.filter(c => c.imagen !== targetImage))
    } else {
      setColoresSeleccionados([...coloresSeleccionados, color])
    }
  }

  const toggleFormatoSeleccionado = (formato, index) => {
    if (formatosSeleccionados[index]) {
      if (formatosSeleccionados[index].indexOf(formato) > -1) {
        formatosSeleccionados[index] = formatosSeleccionados[index].filter(f => f !== formato)
      } else {
        formatosSeleccionados[index].push(formato)
      }
    } else {
      formatosSeleccionados[index] = [formato]
    }
    setFormatosSeleccionados(formatosSeleccionados.map(f => f))
  }

  const toggleAcabadoSeleccionado = (acabado, colorIndex, formatoIndex) => {
    if (acabadosSeleccionados[colorIndex]) {
      if (acabadosSeleccionados[colorIndex][formatoIndex]) {
        if (acabadosSeleccionados[colorIndex][formatoIndex].indexOf(acabado) > -1) {
          acabadosSeleccionados[colorIndex][formatoIndex] = acabadosSeleccionados[colorIndex][formatoIndex].filter(a => a !== acabado)
        } else {
          acabadosSeleccionados[colorIndex][formatoIndex].push(acabado)
        }
      } else {
        acabadosSeleccionados[colorIndex][formatoIndex] = [acabado]
      }
    } else {
      acabadosSeleccionados[colorIndex] = []
      acabadosSeleccionados[colorIndex][formatoIndex] = [acabado]
    }
    setAcabadosSeleccionados(acabadosSeleccionados.map(a => a))
  }

  const seleccionaSerieCompleta = () => {
    setColoresSeleccionados([])
    setAcabadosSeleccionados([])
    setFormatosSeleccionados([])
    setSerieCompletaSeleccionado(true)
  }

  const anadeProducto = () => {
    const visitaId = localStorage.getItem("tau-pamesa-current-visita");
    if (!visitaId) {
      console.error("No hay visita activa")
    } else if (serieCompletaSeleccionado) {
      anadirSerie(visitaId, serie, materialesPromocionesData)
        .then(cierraPestana)
        .catch(console.error)
    } else {
      const productosSeleccionados = new Map()
      coloresSeleccionados.forEach((color, index) => {
        const colorProductos = productos.filter(p => p.color.toLowerCase() === color.color.toLowerCase() && p.decorado == color.decorado)
        colorProductos.forEach(cp => {
          const formatoIndex = formatosSeleccionados[index].indexOf(cp.formato)
          if (formatoIndex > -1) {
            const acabadoIndex = acabadosSeleccionados[index][formatoIndex].indexOf(cp.acabado.toLowerCase())
            if (acabadoIndex > -1) {
              const key = `${cp.color.toLowerCase()}${cp.formato}${cp.acabado.toLowerCase()}`
              if (!productosSeleccionados.has(key)) productosSeleccionados.set(key, cp.articuloId)
            }
          }
        })
      })

      const productIds = [...productosSeleccionados.values()]
      if (productIds.length > 0) {
        Promise.all(productIds.map(pId => anadirProducto(visitaId, pId, materialesPromocionesData, serie)))
          .then(cierraPestana)
          .catch(console.error)
      }
    }
  }

  const cierraPestana = () => {
    //window.open("about:blank", "_self");
    //window.close();
    const username = localStorage.getItem("tau-current-comercial")
    const visitaId = localStorage.getItem("tau-pamesa-current-visita")
    window.location.replace(`/${username}/visita/${visitaId}`)
  }

  const handleToHome = () => {
    const username = localStorage.getItem("tau-current-comercial")
    if (username) {
      window.location.replace(`/${username}`)
    } else {
      window.location.replace("/app-login")
    }
  }

  const transitions = {
    enterRight: "fadeInDown",
    enterLeft: "fadeInUp",
    exitRight: "fadeOutUp",
    exitLeft: "fadeOutDown"
  }

  return (
    <div className={"container mx-auto p-4"}>
      <Header shouldNotDisplayBackButton={!isVisitaSet} onBack={onBack}/>
      <div className={"container mx-auto p-4 max-w-screen-md"}>
        {!isVisitaSet && (
          <div
            className={"flex flex-col justify-center mt-3"}>
            <span className={"my-3 mt-5 text-center text-2xl sm:text-2xl"}>No hay ninguna visita activa.</span>
            <span className={"my-3 mt-4 text-center text-2xl sm:text-2xl"}>Selecciona una visita en la pantalla principal y vuelve a escanear el producto.</span>

            <button onClick={handleToHome} className={"px-4 py-3 rounded-full bg-black text-white text-sm tracking-widest shadow-drop-bottom uppercase mt-5"}>
              Volver a pantalla principal
            </button>
          </div>
        )}
        {isVisitaSet && (
          <>
            <div
              className={"flex justify-center mb-3"}>
              <span className={"my-3 mt-2 text-center text-6xl font-light lowercase sm:text-6xl font-tau-serif words chars splitting"}>{serie}</span>
              <div role={"button"} tabIndex={-1}
                className={
                  "w-12 h-12 md:w-10 md:h-10 rounded-full flex justify-center items-center"
                }
                onClick={() => setAdditionalInfoOpen(true)}
                onKeyDown={() => setAdditionalInfoOpen(true)}
              >
                <HiOutlineInformationCircle className={"text-2xl md:text-xl"} />
              </div>
            </div>
            <div className={"grid grid-cols-1"}>
              <StepWizard transitions={transitions} onStepChange={onStepChange} instance={setWizardInstance}
                className={"mt-4"}>
                <ElegirColor colors={colores} onColorSeleccionado={toggleColorSeleccionado} onSerieCompletaSeleccionado={seleccionaSerieCompleta} coloresSeleccionados={coloresSeleccionados}/>
                <ElegirFormato formatos={formatos} onFormatoSeleccionado={toggleFormatoSeleccionado} coloresSeleccionados={coloresSeleccionados} formatosSeleccionados={formatosSeleccionados}/>
                <ElegirAcabado acabados={acabados} onAcabadoSeleccionado={toggleAcabadoSeleccionado} coloresSeleccionados={coloresSeleccionados} formatosSeleccionados={formatosSeleccionados} acabadosSeleccionados={acabadosSeleccionados}/>
                <ElegirMaterialesPromociones data={materialesPromocionesData} onDataChange={setMaterialesPromocionesData}/>
                <ConfirmarSeleccion
                  serie={serie}
                  serieCompletaSeleccionado={serieCompletaSeleccionado}
                  coloresSeleccionados={coloresSeleccionados}
                  formatosSeleccionados={formatosSeleccionados}
                  acabadosSeleccionados={acabadosSeleccionados}
                  materialesPromocionesData={materialesPromocionesData}
                  onConfirmacion={anadeProducto}
                />
              </StepWizard>
            </div>
            <InfoDialog
              title={`Serie ${serie}`}
              open={additionalInfoOpen}
              onClose={() => setAdditionalInfoOpen(false)}
            >
              <div className={"flex flex-col justify-center items-center my-2"}>
                <span className={"text-xl uppercase text-center"}>Colores</span>
                {colores.filter(c => !c.decorado).map(c => (
                  <div className={"my-3 w-full"} key={c.imagen}>
                    <div
                      className={
                        "flex flex-col justify-center items-center my-2 sm:my-0"
                      }
                    >
                      <span className={"my-2 text-md text-center font-bold uppercase"}>{c.color}</span>
                      <img
                        className=" object-cover rounded-full shadow-drop-bottom h-20 md:h-20 w-full"
                        src={getImageUrl(c)}
                        alt={c.color}
                      />
                    </div>
                    <div
                      className={
                        "flex flex-col justify-center items-center px-4 my-3 sm:my-0"
                      }
                    >
                      <span className={"text-md uppercase text-center"}>Formatos</span>
                      <div className={"grid grid-cols-2 my-1"}>
                        {[...new Set(productos.filter(p => p.color === c.color).map(p => p.formato))].map(f =>
                          <span key={f}
                            className={
                              "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-full text-gray-800 text-center text-sm uppercase cursor-default text-ellipsis overflow-hidden whitespace-nowrap"
                            }
                          >
                            {f}
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        "flex flex-col justify-center items-center px-4 my-3 sm:my-0"
                      }
                    >
                      <span className={"text-md uppercase text-center"}>Acabados</span>
                      <div className={"grid grid-cols-2 my-1"}>
                        {[...new Set(productos.filter(p => p.color === c.color).map(p => p.acabado))].map(a =>
                          <span key={a}
                            className={
                              "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-full text-gray-800 text-center text-sm uppercase cursor-default text-ellipsis overflow-hidden whitespace-nowrap"
                            }
                          >
                            {a}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {colores.filter(c => c.decorado).length > 0 && <span className={"mt-4 text-xl uppercase text-center"}>Decorados</span>}
                {colores.filter(c => c.decorado).map(c => (
                  <div className={"my-3 w-full"} key={c.imagen}>
                    <div
                      className={
                        "flex flex-col justify-center items-center my-3 sm:my-0"
                      }
                    >
                      <span className={"my-2 text-md text-center font-bold uppercase"}>{c.color}</span>
                      <img
                        className=" object-cover rounded-full shadow-drop-bottom h-20 md:h-20 w-full"
                        src={getImageUrl(c)}
                        alt={c.color}
                      />
                    </div>
                    <div
                      className={
                        "flex flex-col justify-center items-center px-4 my-3 sm:my-0"
                      }
                    >
                      <span className={"text-md uppercase text-center"}>Formatos</span>
                      <div className={"grid grid-cols-2 my-1"}>
                        {[...new Set(productos.filter(p => p.color === c.color).map(p => p.formato))].map(f =>
                          <span key={f}
                            className={
                              "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-full text-gray-800 text-center text-sm uppercase cursor-default text-ellipsis overflow-hidden whitespace-nowrap"
                            }
                          >
                            {f}
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        "flex flex-col justify-center items-center px-4 my-2 sm:my-0"
                      }
                    >
                      <span className={"text-md uppercase text-center"}>Acabados</span>
                      <div className={"grid grid-cols-2 my-1"}>
                        {[...new Set(productos.filter(p => p.color === c.color).map(p => p.acabado))].map(a =>
                          <span key={a}
                            className={
                              "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-full text-gray-800 text-center text-sm uppercase cursor-default text-ellipsis overflow-hidden whitespace-nowrap"
                            }
                          >
                            {a}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

            </InfoDialog>
          </>
        )}

      </div>
    </div>
  )
}

export default AnadirProductoPage;